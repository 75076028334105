<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        ULTRASSONOGRAFIA MORFOLÓGICA COM DOPPLER E PERFIL CERVICAL
      </h2>
    </v-card>

    <v-alert
      v-if="!!laudoSelecionado.laudoJson.folhas"
      outlined
      type="warning"
      border="left"
      text=""
    >
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado"
        >Clique aqui para visualizar</v-btn
      >
    </v-alert>

    <!-- Morfolofica: -->

    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col
          cols="12"
          class="form-group"
          v-for="(index, folha) in quantidadeFolhasEditorPersonalizado"
          :key="index"
        >
          <h2>Folha {{ folha + 1 }}</h2>
          <v-btn plain small @click="removeFolha(index)" class="pl-0">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>
      <template v-else>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Estática</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSituacao ? '' : 'text--disabled'
          }`"
        >
          <span>Situação:</span>
          <v-select
            class="mx-2 flex-grow-0"
            :items="['longitudinal', 'transversa']"
            validate-on-blur
            :disabled="!camposExibidos.exibeSituacao"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            v-model="laudoSelecionado.laudoJson.situacao"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeSituacao"
            @click="
              camposExibidos.exibeSituacao = true;
              laudoSelecionado.laudoJson.situacao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeSituacao = false;
              laudoSelecionado.laudoJson.situacao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeApresentacao ? '' : 'text--disabled'
          }`"
        >
          <span>Apresentação:</span>
          <v-select
            :items="['cefálica', 'pélvica', 'córmica']"
            required
            :disabled="!camposExibidos.exibeApresentacao"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.apresentacao"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeApresentacao"
            @click="
              camposExibidos.exibeApresentacao = true;
              laudoSelecionado.laudoJson.apresentacao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeApresentacao = false;
              laudoSelecionado.laudoJson.apresentacao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDorsoFetal ? '' : 'text--disabled'
          }`"
        >
          <span>Dorso fetal:</span>
          <v-select
            :items="[
              'anterior à  direita',
              'anterior à esquerda',
              'posterior à direita',
              'posterior  à esquerda',
              'posterior na linha mediana',
              'anterior na linha mediana',
              'posterior na linha mediana',
            ]"
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeDorsoFetal"
            dense
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.dorsoFetal"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDorsoFetal"
            @click="
              camposExibidos.exibeDorsoFetal = true;
              laudoSelecionado.laudoJson.dorsoFetal = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDorsoFetal = false;
              laudoSelecionado.laudoJson.dorsoFetal = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Vitalidade</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeMovimentosFetaisPresentes
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Movimentos fetais:</span>
          <v-select
            :items="['presentes', 'ausentes', 'reduzidos']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMovimentosFetaisPresentes"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.movimentosFetaisPresentes"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeMovimentosFetaisPresentes"
            @click="
              camposExibidos.exibeMovimentosFetaisPresentes = true;
              laudoSelecionado.laudoJson.movimentosFetaisPresentes = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeMovimentosFetaisPresentes = false;
              laudoSelecionado.laudoJson.movimentosFetaisPresentes = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeBatimentosCardiacosFetais
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Batimentos cardiacos fetais:</span>
          <v-select
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeBatimentosCardiacosFetais"
            dense
            :items="['regulares', 'irregular com arritimia']"
            v-model="laudoSelecionado.laudoJson.batimentosCardiacosFetais"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeBatimentosCardiacosFetais"
            @click="
              camposExibidos.exibeBatimentosCardiacosFetais = true;
              laudoSelecionado.laudoJson.batimentosCardiacosFetais = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeBatimentosCardiacosFetais = false;
              laudoSelecionado.laudoJson.batimentosCardiacosFetais = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeFrequenciaBpm ? '' : 'text--disabled'
          }`"
        >
          <span>Frequência de</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            :disabled="!camposExibidos.exibeFrequenciaBpm"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.frequenciaBpm"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>bpm.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeFrequenciaBpm"
            @click="
              camposExibidos.exibeFrequenciaBpm = true;
              laudoSelecionado.laudoJson.frequenciaBpm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeFrequenciaBpm = false;
              laudoSelecionado.laudoJson.frequenciaBpm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            v-model="laudoSelecionado.laudoJson.comentariosAdcionais001"
            required
            validate-on-blur
            dense
            filled
            outlined
            no-resize
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Comentários adicionais"
          ></v-textarea>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Biometria fetal</h3>
        </v-col>

        <v-col cols="6" :class="`d-flex justify-start align-center ${camposExibidos.exibeDiametroBiparietal ? '' : 'text--disabled'
          }`">
          <span>Diâmetro biparietal:</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos.exibeDiametroBiparietal" class="mx-2 flex-grow-0"
            dense validate-on-blur :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.diametroBiparietal"></v-text-field>
          <span>mm</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeDiametroBiparietal" @click="
            camposExibidos.exibeDiametroBiparietal = true;
          laudoSelecionado.laudoJson.diametroBiparietal = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeDiametroBiparietal = false;
          laudoSelecionado.laudoJson.diametroBiparietal = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col><v-col cols="6" :class="`d-flex justify-start align-center ${camposExibidos.exibeflcc ? '' : 'text--disabled'
          }`">
          <span>FL/CC</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos.exibeflcc" class="mx-2 flex-grow-0"
            dense validate-on-blur :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.flcc"></v-text-field>
          <span>%</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeflcc" @click="
            camposExibidos.exibeflcc = true;
          laudoSelecionado.laudoJson.flcc = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeflcc = false;
          laudoSelecionado.laudoJson.flcc = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="6" :class="`d-flex justify-start align-center ${camposExibidos.exibeCircunferenciaCefalica ? '' : 'text--disabled'
          }`">
          <span>Circunferência cefálica:</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos.exibeCircunferenciaCefalica" validate-on-blur
            class="mx-2 flex-grow-0" dense :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.circunferenciaCefalica"></v-text-field>
          <span>mm</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeCircunferenciaCefalica" @click="
            camposExibidos.exibeCircunferenciaCefalica = true;
          laudoSelecionado.laudoJson.circunferenciaCefalica = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeCircunferenciaCefalica = false;
          laudoSelecionado.laudoJson.circunferenciaCefalica = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>
        <v-col cols="6" :class="`d-flex justify-start align-center ${camposExibidos.exibeccca ? '' : 'text--disabled'
          }`">
          <span>CC/CA:</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos.exibeccca" validate-on-blur
            class="mx-2 flex-grow-0" dense :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.ccca"></v-text-field>
          <span>%</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeccca" @click="
            camposExibidos.exibeccca = true;
          laudoSelecionado.laudoJson.ccca = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeccca = false;
          laudoSelecionado.laudoJson.ccca = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="6" :class="`d-flex justify-start align-center ${camposExibidos.exibeCircunferenciaAbdominal ? '' : 'text--disabled'
          }`">
          <span>Circunferência abdominal:</span>
          <v-text-field v-mask="''" required class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeCircunferenciaAbdominal" validate-on-blur
            v-model="laudoSelecionado.laudoJson.circunferenciaAbdominal"
            :readonly="carregamentoDoLaudo || !podeEditar"></v-text-field>
          <span>mm</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeCircunferenciaAbdominal" @click="
            camposExibidos.exibeCircunferenciaAbdominal = true;
          laudoSelecionado.laudoJson.circunferenciaAbdominal = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeCircunferenciaAbdominal = false;
          laudoSelecionado.laudoJson.circunferenciaAbdominal = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>
        <v-col cols="6" :class="`d-flex justify-start align-center ${camposExibidos.exibeflca ? '' : 'text--disabled'
          }`">
          <span>FL/CA:</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos.exibeflca" validate-on-blur
            class="mx-2 flex-grow-0" dense :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.flca"></v-text-field>
          <span>%</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeflca" @click="
            camposExibidos.exibeflca = true;
          laudoSelecionado.laudoJson.flca = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeflca = false;
          laudoSelecionado.laudoJson.flca = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="6" :class="`d-flex justify-start align-center ${camposExibidos.exibeComprimentoUmero ? '' : 'text--disabled'
          }`">
          <span>Comprimento do úmero:</span>
          <v-text-field v-mask="''" required class="mx-2 flex-grow-0" :disabled="!camposExibidos.exibeComprimentoUmero"
            dense validate-on-blur v-model="laudoSelecionado.laudoJson.comprimentoUmero"
            :readonly="carregamentoDoLaudo || !podeEditar"></v-text-field>
          <span>mm</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeComprimentoUmero" @click="
            camposExibidos.exibeComprimentoUmero = true;
          laudoSelecionado.laudoJson.comprimentoUmero = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeComprimentoUmero = false;
          laudoSelecionado.laudoJson.comprimentoUmero = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>
        <v-col cols="6" :class="`d-flex justify-start align-center ${camposExibidos.exibeIC ? '' : 'text--disabled'
          }`">
          <span>IC</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos.exibeIC" validate-on-blur
            class="mx-2 flex-grow-0" dense :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.ic"></v-text-field>
          <span>%</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeIC" @click="
            camposExibidos.exibeIC = true;
          laudoSelecionado.laudoJson.ic = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeIC = false;
          laudoSelecionado.laudoJson.ic = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeComprimentoUlna ? '' : 'text--disabled'
          }`"
        >
          <span>Comprimento da ulna:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeComprimentoUlna"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoUlna"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeComprimentoUlna"
            @click="
              camposExibidos.exibeComprimentoUlna = true;
              laudoSelecionado.laudoJson.comprimentoUlna = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeComprimentoUlna = false;
              laudoSelecionado.laudoJson.comprimentoUlna = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeComprimentoRadio ? '' : 'text--disabled'
          }`"
        >
          <span>Comprimento do rádio:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            :disabled="!camposExibidos.exibeComprimentoRadio"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoRadio"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeComprimentoRadio"
            @click="
              camposExibidos.exibeComprimentoRadio = true;
              laudoSelecionado.laudoJson.comprimentoRadio = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeComprimentoRadio = false;
              laudoSelecionado.laudoJson.comprimentoRadio = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeComprimentoFemur ? '' : 'text--disabled'
          }`"
        >
          <span>Comprimento do femur:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            :disabled="!camposExibidos.exibeComprimentoFemur"
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comprimentoFemur"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeComprimentoFemur"
            @click="
              camposExibidos.exibeComprimentoFemur = true;
              laudoSelecionado.laudoJson.comprimentoFemur = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeComprimentoFemur = false;
              laudoSelecionado.laudoJson.comprimentoFemur = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeComprimentoTibia ? '' : 'text--disabled'
          }`"
        >
          <span>Comprimento da tíbia:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoTibia"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeComprimentoTibia"
            @click="
              camposExibidos.exibeComprimentoTibia = true;
              laudoSelecionado.laudoJson.comprimentoTibia = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeComprimentoTibia = false;
              laudoSelecionado.laudoJson.comprimentoTibia = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeComprimentoFibula ? '' : 'text--disabled'
          }`"
        >
          <span>Comprimento da fíbula:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeComprimentoFibula"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoFibula"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeComprimentoFibula"
            @click="
              camposExibidos.exibeComprimentoFibula = true;
              laudoSelecionado.laudoJson.comprimentoFibula = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeComprimentoFibula = false;
              laudoSelecionado.laudoJson.comprimentoFibula = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeComprimentoPe ? '' : 'text--disabled'
          }`"
        >
          <span>Comprimento do pé:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeComprimentoPe"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoPe"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeComprimentoPe"
            @click="
              camposExibidos.exibeComprimentoPe = true;
              laudoSelecionado.laudoJson.comprimentoPe = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeComprimentoPe = false;
              laudoSelecionado.laudoJson.comprimentoPe = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDiametroCerebelo ? '' : 'text--disabled'
          }`"
        >
          <span>Diâmetro do cerebelo:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            :disabled="!camposExibidos.exibeDiametroCerebelo"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.diametroCerebelo"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDiametroCerebelo"
            @click="
              camposExibidos.exibeDiametroCerebelo = true;
              laudoSelecionado.laudoJson.diametroCerebelo = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDiametroCerebelo = false;
              laudoSelecionado.laudoJson.diametroCerebelo = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeCisternaMagna ? '' : 'text--disabled'
          }`"
        >
          <span>Cisterna magna:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCisternaMagna"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.cisternaMagna"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeCisternaMagna"
            @click="
              camposExibidos.exibeCisternaMagna = true;
              laudoSelecionado.laudoJson.cisternaMagna = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeCisternaMagna = false;
              laudoSelecionado.laudoJson.cisternaMagna = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibePregaCutaneaOccipital ? '' : 'text--disabled'
          }`"
        >
          <span>Prega cutânea occipital:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePregaCutaneaOccipital"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pregaCutaneaOccipital"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibePregaCutaneaOccipital"
            @click="
              camposExibidos.exibePregaCutaneaOccipital = true;
              laudoSelecionado.laudoJson.pregaCutaneaOccipital = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibePregaCutaneaOccipital = false;
              laudoSelecionado.laudoJson.pregaCutaneaOccipital = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeAtrioVentriculoLateral ? '' : 'text--disabled'
          }`"
        >
          <span>Átrio do ventrículo lateral:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeAtrioVentriculoLateral"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.atrioVentriculoLateral"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeAtrioVentriculoLateral"
            @click="
              camposExibidos.exibeAtrioVentriculoLateral = true;
              laudoSelecionado.laudoJson.atrioVentriculoLateral = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeAtrioVentriculoLateral = false;
              laudoSelecionado.laudoJson.atrioVentriculoLateral = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeOssoNasal ? '' : 'text--disabled'
          }`"
        >
          <span>Osso Nasal:</span>
          <v-text-field
            v-mask="''"
            required
            :disabled="!camposExibidos.exibeOssoNasal"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ossoNasal"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeOssoNasal"
            @click="
              camposExibidos.exibeOssoNasal = true;
              laudoSelecionado.laudoJson.ossoNasal = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeOssoNasal = false;
              laudoSelecionado.laudoJson.ossoNasal = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDistanciaInterobitariaInterna
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Distância interorbitária interna:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            :disabled="!camposExibidos.exibeDistanciaInterobitariaInterna"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.distanciaInterobitariaInterna"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDistanciaInterobitariaInterna"
            @click="
              camposExibidos.exibeDistanciaInterobitariaInterna = true;
              laudoSelecionado.laudoJson.distanciaInterobitariaInterna =
                undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDistanciaInterobitariaInterna = false;
              laudoSelecionado.laudoJson.distanciaInterobitariaInterna =
                undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDistanciaInterobitariaExterna
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Distância interorbitária externa:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            :disabled="!camposExibidos.exibeDistanciaInterobitariaExterna"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.distanciaInterobitariaExterna"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDistanciaInterobitariaExterna"
            @click="
              camposExibidos.exibeDistanciaInterobitariaExterna = true;
              laudoSelecionado.laudoJson.distanciaInterobitariaExterna =
                undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDistanciaInterobitariaExterna = false;
              laudoSelecionado.laudoJson.distanciaInterobitariaExterna =
                undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibePesoFetal ? '' : 'text--disabled'
          }`"
        >
          <span>Peso fetal:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePesoFetal"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pesoFetal"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>g</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibePesoFetal"
            @click="
              camposExibidos.exibePesoFetal = true;
              laudoSelecionado.laudoJson.pesoFetal = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibePesoFetal = false;
              laudoSelecionado.laudoJson.pesoFetal = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Morfologia Fetal</h3>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeCranio ? '' : 'text--disabled'
          }`">
          <span>Crânio:</span>
          <v-select :items="[
            'crânio íntegro, com formato, dimensões e ecogenicidade preservadas',
            'irregular. Sinal do limão presente',
            'ecogenicidade reduzida',
            'dimensões reduzidas',
            'dimensões aumentadas',
            'falha de continuidade no crânio, na região',
            'outro',
          ]" 
        multiple
          required 
          class="mx-2 flex-grow-0" 
          :disabled="!camposExibidos.exibeCranio"
           dense validate-on-blur
            v-model="laudoSelecionado.laudoJson.cranio" 
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"></v-select>

            <v-text-field v-if="cranio.includes('falha de continuidade no crânio, na região')" class="mx-2 flex-grow-0" 
            dense 
            :disabled="!camposExibidos.exibeCranio"  
            v-model="laudoSelecionado.laudoJson.cranioFalta"
            :readonly="carregamentoDoLaudo || !podeEditar"
            >
          </v-text-field>
          <span v-if="cranio.includes('falha de continuidade no crânio, na região')" >com protrusão de tecido nervoso e meninges.</span>
          <v-textarea v-if="cranio.includes('outro')" required validate-on-blur
            class="mx-2 flex-grow-0" dense filled outlined no-resize label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisCranio"
            :readonly="carregamentoDoLaudo || !podeEditar"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeCranio" @click="
            camposExibidos.exibeCranio = true;
          laudoSelecionado.laudoJson.cranio = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisCranio = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeCranio = false;
          laudoSelecionado.laudoJson.cranio = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisCranio = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeEncefalo ? '' : 'text--disabled'
          }`">
          <span>Encéfalo:</span>
          <v-select :items="[
            'a foice do cérebro, os ventrículos, os plexos coróides, o cavum do septo pelúcido, os tálamos e a cisterna magna foram observados, todos de aspecto ecográfico normal (exame realizado por via transabdominal)',
            'não avaliado por dificuldades técnicas',
            
            'átrio dos ventrículos laterais dilatados, medindo ',
            'presença de cistos de plexo coroide, medindo ',
            'cavum do septo pelúcido não identificado',
            'corpo caloso não identificado',
            'tálamos fundidos',
            'cisterna magna obliterada',
            'cisterna magna aumentada',
            'cerebelo hipoplásico',
            'vermis cerebelar hipoplástico',
            'outro',
          ]"
          multiple
          required class="mx-2 flex-grow-0" dense :disabled="!camposExibidos.exibeEncefalo" validate-on-blur
            v-model="laudoSelecionado.laudoJson.encefalo" :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"></v-select>
            <v-text-field v-if="laudoSelecionado.laudoJson.encefalo == 'átrio dos ventrículos laterais dilatados, medindo ' || 
            laudoSelecionado.laudoJson.encefalo == 'presença de cistos de plexo coroide, medindo '" class="mx-2 flex-grow-0" 
            dense 
            :disabled="!camposExibidos.exibeCranio"  
            v-model="laudoSelecionado.laudoJson.encefaloMedindo"
            :readonly="carregamentoDoLaudo || !podeEditar"
            >
          </v-text-field>
          <span v-if="encefalo.includes('átrio dos ventrículos laterais dilatados, medindo ') || 
            encefalo.includes('presença de cistos de plexo coroide, medindo ')" > mm. </span>
          <v-textarea v-if="encefalo.includes('outro')" required validate-on-blur filled outlined
            class="mx-2 flex-grow-0" dense no-resize label="Comentários adicionais"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisEncefalo"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeEncefalo" @click="
            camposExibidos.exibeEncefalo = true;
          laudoSelecionado.laudoJson.encefalo = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisEncefalo =
            undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeEncefalo = false;
          laudoSelecionado.laudoJson.encefalo = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisEncefalo =
            undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="9" :class="`d-flex justify-start align-center ${camposExibidos.exibeFace ? '' : 'text--disabled'
          }`">
          <span>Face:</span>
          <v-select :items="[
            'distância interorbitária normal, lábios e nariz visibilizados. Perfil de aspecto normal, com adequado alinhamento fronto-mandibular',
            'não avaliado por dificuldades técnicas',
            
            'fenda labial unilateral',
            'fenda labial bilateral',
            'fenda palatina',
            'osso nasal não identificado',
            'redução da distância interorbitária',
            'aumento da distância interorbitária',
            'redução da dimensão do queixo',
            'outro',
          ]" multiple required :disabled="!camposExibidos.exibeFace" class="mx-2 flex-grow-0" dense validate-on-blur
            v-model="laudoSelecionado.laudoJson.face" :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"></v-select>
         
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeFace" @click="
            camposExibidos.exibeFace = true;
          laudoSelecionado.laudoJson.face = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisFace = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeFace = false;
          laudoSelecionado.laudoJson.face = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisFace = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>
        <v-col cols="3" v-if="face.includes('outro')" :class="`d-flex justify-start align-center ${camposExibidos.exibeFace ? '' : 'text--disabled'
          }`">
         
          <v-textarea  required validate-on-blur filled
            class="mx-2 flex-grow-0" dense outlined no-resize label="Comentários adicionais"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisFace"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeFace" @click="
            camposExibidos.exibeFace = true;
          laudoSelecionado.laudoJson.face = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisFace = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeFace = false;
          laudoSelecionado.laudoJson.face = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisFace = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeColuna ? '' : 'text--disabled'
          }`">
          <span>Coluna:</span>
          <v-select :items="[
            'foram observadas em cortes sagital, coronal e transversal as regiões cervical, torácica, lombar e sacra, todas de aspectos normais',
            'não avaliado por dificuldades técnicas',
            'desvio do eixo da coluna',
            'falha de fechamento da coluna com exteriorização de tecido nervoso e meninges',
            'outro',
          ]"  multiple required class="mx-2 flex-grow-0" :disabled="!camposExibidos.exibeColuna" dense
            v-model="laudoSelecionado.laudoJson.coluna" :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"></v-select>
            
          <v-textarea required v-if="coluna.includes('outro')" validate-on-blur filled outlined
            class="mx-2 flex-grow-0" dense no-resize label="Comentários adicionais"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisColuna"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeColuna" @click="
            camposExibidos.exibeColuna = true;
          laudoSelecionado.laudoJson.coluna = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisColuna = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeColuna = false;
          laudoSelecionado.laudoJson.coluna = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisColuna = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeTorax ? '' : 'text--disabled'
          }`">
          <span>Tórax:</span>
          <v-select :items="[
            'não avaliado por dificuldades técnicas',
            'pulmão de dimensões normais e ecogenicidade preservada. Diafragma íntegro',
            
            
            'outro',
          ]" multiple required validate-on-blur class="mx-2 flex-grow-0" dense v-model="laudoSelecionado.laudoJson.torax"
            :readonly="carregamentoDoLaudo || !podeEditar" :clearable="podeEditar"
            :disabled="!camposExibidos.exibeTorax"></v-select>

            <!-- 'diafragma integro e ausencia de derrames',
            'derrame pleural discreto',
            'derrame pleural importante ',
            'imagem ecogenica intralobar',
            'imagem ecogenica extralobar',
            'imagem ecogenica com cistos maiores que dois centimetros',
            'imagem ecogenica com microcistos',
            'bolha gástrica intratoracica',
            'fígado em região intratorácica', -->
          <v-textarea v-if="torax.includes('outro')" required validate-on-blur
            class="mx-2 flex-grow-0" dense filled outlined no-resize label="Comentários adicionais"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisTorax"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeTorax" @click="
            camposExibidos.exibeTorax = true;
          laudoSelecionado.laudoJson.torax = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisTorax = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeTorax = false;
          laudoSelecionado.laudoJson.torax = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisTorax = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeCoracao ? '' : 'text--disabled'
          }`">
          <span>Coração:</span>
          <v-select :items="[
            'não avaliado por dificuldades técnicas',
            'Aparentemente normal com aorta abdominal à esquerda da coluna e presença de veia cava inferior situada à direita',
            'Corte de quatro câmaras de aspecto ecográfico normal para a idade gestacional',
            'Corte dos três vasos e traqueia visibilizado e de aspecto normal. Timo visivel',
            'Artéria pulmonar saindo do ventrículo direito',
            'Aorta saindo do ventrículo esquerdo',
            'Quatro câmaras assimetricas com dominância esquerda',
            'Quatro câmaras assimétricas com dominância direita',
            'Presença de dois vasos no corte 3VT',
            'Presença  de quatro vasos no corte 3VT',
            'Traqueia localizada entre a aorta e pulmonar',
            'Pulmonar menor que a  aorta',
            'Dimensão da aorta similar a VCS',
            'Aorta originando-se do ventrículo direito',
            'Pulmonar originando-se do ventrículo esquerdo',
            'Veia ázigos dilatada',
            'outro',
          ]" required validate-on-blur multiple class="mx-2 flex-grow-0" dense
            v-model="laudoSelecionado.laudoJson.coracao" 
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar" :disabled="!camposExibidos.exibeCoracao"></v-select>
            
          <v-textarea v-if="coracao.includes('outro')" required validate-on-blur
            class="mx-2 flex-grow-0" dense filled outlined no-resize label="Comentários adicionais"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisCoracao"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeCoracao" @click="
            camposExibidos.exibeCoracao = true;
          laudoSelecionado.laudoJson.coracao = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisCoracao =
            undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeCoracao = false;
          laudoSelecionado.laudoJson.coracao = undefined;
          laudoSelecionado.laudoJson.comentariosAdcionaisCoracao =
            undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <!-- <v-col cols="12" class="d-flex justify-start align-center">
        <h3>Coração</h3>
      </v-col> -->

        <!-- <div class="pt-5">
        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoUm"
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          label="Substituir não avaliado por dificuldades técnicas por não avaliado por dificuldades técnicas."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoDois"
          label="Aparentemente normal com aorta abdominal à esquerda da coluna e presença de veia cava inferior situada à direita."
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :select="true"
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoTres"
          :readonly="carregamentoDoLaudo || !podeEditar"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Corte de quatro câmaras de aspecto ecografico normal para a idade gestacional."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoQuatro"
          :readonly="carregamentoDoLaudo || !podeEditar"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Corte dos três vasos e traqueia visibilizado e de aspecto normal. Timo visivel."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoCinco"
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          label="Quatro câmaras assimetricas com dominância esquerda."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoSeis"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Quatro câmaras assimétricas com dominância direita."
          :readonly="carregamentoDoLaudo || !podeEditar"
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoSete"
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          label="Presença de dois vasos no corte 3VT."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoOito"
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          label="Presença  de quatro vasos no corte 3VT."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoNove"
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          label="Traqueia localizada entre a aorta e pulmonar."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoDez"
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          label="Pulmonar menor que a  aorta."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoOnze"
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          label="Dimensão da aorta similar a VCS."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoDoze"
          class="d-flex justify-content-start align-items-center mt-n1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          label="Aorta originando-se do ventrículo direito."
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoTreze"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Pulmonar originando-se do ventrículo esquerdo."
          :readonly="carregamentoDoLaudo || !podeEditar"
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoQuatorze"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Veia ázigos dilatada."
          :readonly="carregamentoDoLaudo || !podeEditar"
          validate-on-blur
        ></v-checkbox>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.coracaoQuinze"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Outro."
          :readonly="carregamentoDoLaudo || !podeEditar"
          validate-on-blur
        ></v-checkbox>

        <v-textarea
          v-if="laudoSelecionado.laudoJson.coracaoQuinze"
          required
          alidate-on-blur
          filled
          outlined
          :readonly="carregamentoDoLaudo || !podeEditar"
          no-resize
          label="Comentários adicionais"
          v-model="laudoSelecionado.laudoJson.outroQuinze"
        ></v-textarea>
      </div> -->

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeAbdomeUm ? '' : 'text--disabled'
          }`">
          <span>Abdômen:</span>
          <v-select :items="[
            'não avaliado por dificuldades técnicas',
            'parede abdominal anterior íntegra, estômago visível em sua localização habitual. Vesícula biliar tópica. Inserção normal do cordão umbilical',
            'estômago com dimensões reduzidas',
            'sinal de dupla bolha',
            'exteriorização de alças intestinais, compatível com gastrosquise',
            'exteriorização de alças intestinais, recobertas por membrana, compatível com onfalocele',
            'exteriorização de alças intestinais e fígado, recobertas por membrana, compatível com onfalocele',
            'outro',
          ]" required :disabled="!camposExibidos.exibeAbdomeUm" class="mx-2 flex-grow-0" dense validate-on-blur
            multiple v-model="laudoSelecionado.laudoJson.abdomeUm" :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"></v-select>
          <v-textarea required v-if="abdomeUm.includes('outro')" validate-on-blur filled
            class="mx-2 flex-grow-0" dense outlined :readonly="carregamentoDoLaudo || !podeEditar" no-resize
            label="Comentários adicionais" v-model="laudoSelecionado.laudoJson.abdomeOutro"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeAbdomeUm" @click="
            camposExibidos.exibeAbdomeUm = true;
          laudoSelecionado.laudoJson.abdomeUm = undefined;
          laudoSelecionado.laudoJson.abdomeOutro = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeAbdomeUm = false;
          laudoSelecionado.laudoJson.abdomeUm = undefined;
          laudoSelecionado.laudoJson.abdomeOutro = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeSistemaUrinario ? '' : 'text--disabled'
          }`">
          <span>Sistema urinário:</span>
          <v-select :items="[
            'aparentemente normal com os rins tópicos de estrutura normal, sem dilatação pielocaliciais e bexiga bem visualizada. Ureteres não visualizados (normal)',
            'aparentemente normal com os rins tópicos de estrutura normal, com dilatação pielocaliciais e bexiga bem visualizada. Ureteres não visualizados (normal)',
            'não avaliado por dificuldades técnicas',
            'rins não visualizados em sua topografia habitual',
            'rim direito não visualizado em sua topografia habitual',
            'rim esquerdo não visualizado em sua topografia habitual',
            'rim esquerdo localizado em topografia mais baixa que o habitual',
            'rim direito localizado em topografia mais baixa que o habitual',
            'presença de múltiplos cistos no rim direito',
            'presença de múltiplos cistos no rim esquerda',
            'presença de múltiplos cistos nos rins',
            'rins hiperecogênicos',
            'bexiga aumentada de volume com uretra dilatada',
            'outro',
          ]" :readonly="carregamentoDoLaudo || !podeEditar" :clearable="podeEditar"
            multiple
            :disabled="!camposExibidos.exibeSistemaUrinario" class="mx-2 flex-grow-0" dense validate-on-blur
            v-model="laudoSelecionado.laudoJson.sistemaUrinario"></v-select>
          <v-textarea v-if="sistemaUrinario.includes('outro')" required validate-on-blur filled
            outlined class="mx-2 flex-grow-0" dense :readonly="carregamentoDoLaudo || !podeEditar" no-resize
            label="Comentários adicionais" v-model="laudoSelecionado.laudoJson.sistemaUrinarioOutro"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeSistemaUrinario" @click="
            camposExibidos.exibeSistemaUrinario = true;
          laudoSelecionado.laudoJson.sistemaUrinario = undefined;
          laudoSelecionado.laudoJson.sistemaUrinarioOutro = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeSistemaUrinario = false;
          laudoSelecionado.laudoJson.sistemaUrinario = undefined;
          laudoSelecionado.laudoJson.sistemaUrinarioOutro = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeDiametroLongitudinalRimDireito
            ? ''
            : 'text--disabled'
          }`">
          <span>Diâmetro longitudinal do rim direito:</span>
          <v-text-field v-mask="''" required validate-on-blur class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeDiametroLongitudinalRimDireito" dense
            v-model="laudoSelecionado.laudoJson.diametroLongitudinalRimDireito"
            :readonly="carregamentoDoLaudo || !podeEditar"></v-text-field>
          <span>mm</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeDiametroLongitudinalRimDireito" @click="
            camposExibidos.exibeDiametroLongitudinalRimDireito = true;
          laudoSelecionado.laudoJson.diametroLongitudinalRimDireito =
            undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeDiametroLongitudinalRimDireito = false;
          laudoSelecionado.laudoJson.diametroLongitudinalRimDireito =
            undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeDiametroLongitudinalRimEsquerdo
            ? ''
            : 'text--disabled'
          }`">
          <span>Diâmetro longitudinal do rim esquerdo:</span>
          <v-text-field v-mask="''" class="mx-2 flex-grow-0" dense required validate-on-blur
            :disabled="!camposExibidos.exibeDiametroLongitudinalRimEsquerdo"
            v-model="laudoSelecionado.laudoJson.diametroLongitudinalRimEsquerdo"
            :readonly="carregamentoDoLaudo || !podeEditar"></v-text-field>
          <span>mm</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeDiametroLongitudinalRimEsquerdo" @click="
            camposExibidos.exibeDiametroLongitudinalRimEsquerdo = true;
          laudoSelecionado.laudoJson.diametroLongitudinalRimEsquerdo =
            undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeDiametroLongitudinalRimEsquerdo = false;
          laudoSelecionado.laudoJson.diametroLongitudinalRimEsquerdo =
            undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibePelveRenalDireita ? '' : 'text--disabled'
          }`">
          <span>Pelve renal direita:</span>
          <v-text-field v-mask="''" class="mx-2 flex-grow-0" dense required validate-on-blur
            :disabled="!camposExibidos.exibePelveRenalDireita" v-model="laudoSelecionado.laudoJson.pelveRenalDireita"
            :readonly="carregamentoDoLaudo || !podeEditar"></v-text-field>
          <span>mm</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibePelveRenalDireita" @click="
            camposExibidos.exibePelveRenalDireita = true;
          laudoSelecionado.laudoJson.pelveRenalDireita = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibePelveRenalDireita = false;
          laudoSelecionado.laudoJson.pelveRenalDireita = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibePelveRenalEsquerda ? '' : 'text--disabled'
          }`">
          <span>Pelve renal esquerda:</span>
          <v-text-field v-mask="''" required validate-on-blur :disabled="!camposExibidos.exibePelveRenalEsquerda"
            class="mx-2 flex-grow-0" dense v-model="laudoSelecionado.laudoJson.pelveRenalEsquerda"
            :readonly="carregamentoDoLaudo || !podeEditar"></v-text-field>
          <span>mm</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibePelveRenalEsquerda" @click="
            camposExibidos.exibePelveRenalEsquerda = true;
          laudoSelecionado.laudoJson.pelveRenalEsquerda = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibePelveRenalEsquerda = false;
          laudoSelecionado.laudoJson.pelveRenalEsquerda = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeSexoGenitaliaExternaMorfologicamente
            ? ''
            : 'text--disabled'
          }`">
          <span>Sexo - genitália externa morfologicamente</span>
          <v-select :items="['masculina', 'feminina', 'não identificada', 'ambígua']"
            :readonly="carregamentoDoLaudo || !podeEditar" :clearable="podeEditar" validate-on-blur :disabled="!camposExibidos.exibeSexoGenitaliaExternaMorfologicamente
              " class="mx-2 flex-grow-0" dense v-model="laudoSelecionado.laudoJson.sexoGenitaliaExternaMorfologicamente
    "></v-select>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeSexoGenitaliaExternaMorfologicamente" @click="
            camposExibidos.exibeSexoGenitaliaExternaMorfologicamente = true;
          laudoSelecionado.laudoJson.sexoGenitaliaExternaMorfologicamente =
            undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeSexoGenitaliaExternaMorfologicamente = false;
          laudoSelecionado.laudoJson.sexoGenitaliaExternaMorfologicamente =
            undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeMembros ? '' : 'text--disabled'
          }`">
          <span>Membros:</span>
          <v-select :items="[
            'não avaliado por dificuldades técnicas',
            'aparentemente normais sendo identificados quatro membros simétricos',
            'desvio do eixo do pé direito',
            'sem anormalidades em seus diferentes segmentos',
            'mão direita não visualizada',
            'mão esquerda não visualizada',
            'Mmãos não visualizada',
            'desvio do eixo dos pés',
            'desvio do eixo do pé esquerdo',
            'polidactilia em mão direita',
            'polidactilia em mão esquerda',
            'polidactilia em ambas as mãos',
            'outro',
          ]" multiple :readonly="carregamentoDoLaudo || !podeEditar" :clearable="podeEditar"
            :disabled="!camposExibidos.exibeMembros" class="mx-2 flex-grow-0" dense validate-on-blur
            v-model="laudoSelecionado.laudoJson.membros"></v-select>
          <v-textarea v-if="membros.includes('outro')" required validate-on-blur
            class="mx-2 flex-grow-0" dense filled outlined no-resize :readonly="carregamentoDoLaudo || !podeEditar"
            label="Comentários adicionais" v-model="laudoSelecionado.laudoJson.membrosOutro"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeMembros" @click="
            camposExibidos.exibeMembros = true;
          laudoSelecionado.laudoJson.membros = undefined;
          laudoSelecionado.laudoJson.membrosOutro = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeMembros = false;
          laudoSelecionado.laudoJson.membros = undefined;
          laudoSelecionado.laudoJson.membrosOutro = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeLiquidoAmniotico ? '' : 'text--disabled'
          }`">
          <span>Líquido amniótico:</span>
          <v-select :items="[
            'normal para idade gestacional',
            'reduzido para idade gestacional',
            'aumentado para idade gestacional',
            'ausente',
            'outro',
          ]" :readonly="carregamentoDoLaudo || !podeEditar" :clearable="podeEditar"
            :disabled="!camposExibidos.exibeLiquidoAmniotico" class="mx-2 flex-grow-0" dense validate-on-blur
            v-model="laudoSelecionado.laudoJson.liquidoAmniotico"></v-select>
          <v-textarea v-if="laudoSelecionado.laudoJson.liquidoAmniotico == 'outro'" required validate-on-blur filled dense
            class="mx-2 flex-grow-0" outlined no-resize :readonly="carregamentoDoLaudo || !podeEditar"
            label="Comentários adicionais" v-model="laudoSelecionado.laudoJson.liquidoAmnioticoOutro"></v-textarea>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeLiquidoAmniotico" @click="
            camposExibidos.exibeLiquidoAmniotico = true;
          laudoSelecionado.laudoJson.liquidoAmniotico = undefined;
          laudoSelecionado.laudoJson.liquidoAmnioticoOutro = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeLiquidoAmniotico = false;
          laudoSelecionado.laudoJson.liquidoAmniotico = undefined;
          laudoSelecionado.laudoJson.liquidoAmnioticoOutro = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea required validate-on-blur dense filled outlined no-resize
            :readonly="carregamentoDoLaudo || !podeEditar" label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.liquidoAmnioticoOutro2"></v-textarea>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Placenta</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeLocalizacao ? '' : 'text--disabled'
          }`"
        >
          <span>Localização:</span>
          <v-select
            :items="[
              'anterior alta',
              'posterior alta',
              'anterior baixa',
              'posterior baixa',
              'fúndica',
              'outro',
            ]"
            validate-on-blur
            :disabled="!camposExibidos.exibeLocalizacao"
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.localizacao"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-textarea
            v-if="laudoSelecionado.laudoJson.localizacao == 'outro'"
            required
            validate-on-blur
            filled
            dense
            class="mx-2 flex-grow-0"
            outlined
            no-resize
            label="Comentários adicionais"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.localizacaoOutro"
          ></v-textarea>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeLocalizacao"
            @click="
              camposExibidos.exibeLocalizacao = true;
              laudoSelecionado.laudoJson.localizacao = undefined;
              laudoSelecionado.laudoJson.localizacaoOutro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeLocalizacao = false;
              laudoSelecionado.laudoJson.localizacao = undefined;
              laudoSelecionado.laudoJson.localizacaoOutro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeEspessura ? '' : 'text--disabled'
          }`"
        >
          <span>Espessura:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEspessura"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.espessura"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-text-field>
          <span>mm</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeEspessura"
            @click="
              camposExibidos.exibeEspessura = true;
              laudoSelecionado.laudoJson.espessura = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeEspessura = false;
              laudoSelecionado.laudoJson.espessura = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeTextura ? '' : 'text--disabled'
          }`"
        >
          <span>Textura:</span>
          <v-select
            :items="['homogênea', 'heterogênea']"
            validate-on-blur
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeTextura"
            dense
            v-model="laudoSelecionado.laudoJson.textura"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeTextura"
            @click="
              camposExibidos.exibeTextura = true;
              laudoSelecionado.laudoJson.textura = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeTextura = false;
              laudoSelecionado.laudoJson.textura = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeGrauUm ? '' : 'text--disabled'
          }`"
        >
          <span>Grau:</span>
          <v-select
            :items="['0', 'I', 'II (Grannum)']"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeGrauUm"
            v-model="laudoSelecionado.laudoJson.grauUm"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeGrauUm"
            @click="
              camposExibidos.exibeGrauUm = true;
              laudoSelecionado.laudoJson.grauUm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeGrauUm = false;
              laudoSelecionado.laudoJson.grauUm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            required
            validate-on-blur
            dense
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisDois"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-textarea>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Cordão umbilical</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeInsercao ? '' : 'text--disabled'
          }`"
        >
          <span>Inserção:</span>
          <v-select
            :items="['normal', 'na periferia da placenta', 'velamentosa']"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeInsercao"
            v-model="laudoSelecionado.laudoJson.insercao"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeInsercao"
            @click="
              camposExibidos.exibeInsercao = true;
              laudoSelecionado.laudoJson.insercao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeInsercao = false;
              laudoSelecionado.laudoJson.insercao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeVasos ? '' : 'text--disabled'
          }`"
        >
          <span>Vasos:</span>
          <v-select
            :items="['3 (duas artérias e 1 veia)', '2 (uma artéria e 1 veia)']"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.vasos"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeVasos"
            dense
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeVasos"
            @click="
              camposExibidos.exibeVasos = true;
              laudoSelecionado.laudoJson.vasos = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeVasos = false;
              laudoSelecionado.laudoJson.vasos = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            required
            validate-on-blur
            filled
            dense
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisTres"
            :readonly="carregamentoDoLaudo || !podeEditar"
          ></v-textarea>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão morfológica</h3>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span>Gestação única com feto vivo.</span>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span
            :class="`${
              camposExibidos.exibeIdadeSemanas ? '' : 'text--disabled'
            }`"
          >
            Idade gestacional baseado na biometria fetal atual de
          </span>
          <v-text-field
            v-mask="'##'"
            required
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeIdadeSemanas"
            dense
            validate-on-blur
            v-model="
              laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
          >
          </v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeIdadeSemanas"
            @click="
              camposExibidos.exibeIdadeSemanas = true;
              laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas =
                undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeIdadeSemanas = false;
              laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas =
                undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <span
            :class="`${
              camposExibidos.exibeIdadeSemanas ? '' : 'text--disabled'
            }`"
            >semanas e</span
          >
          <v-text-field
            v-mask="'##'"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeDiasFt"
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="
              laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias
            "
          >
          </v-text-field>
          <span :class="`${camposExibidos.exibeDiasFt ? '' : 'text--disabled'}`"
            >dias
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="!camposExibidos.exibeDiasFt"
              @click="
                camposExibidos.exibeDiasFt = true;
                laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias =
                  undefined;
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              flat
              icon
              v-else
              @click="
                camposExibidos.exibeDiasFt = false;
                laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias =
                  undefined;
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >,</span
          >
          <v-select
            :items="[
              'compatível com a idade gestacional baseada na DUM.',
              'incompatível com a idade gestacional baseada na DUM.',
              'compatível com a idade gestacional corrigida pela ultrassonografia precoce.',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="
              !camposExibidos.exibeIdadeGestacionalBiometriaFetalSemanas
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.compativelIncompativel"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeIdadeGestacionalBiometriaFetalSemanas"
            @click="
              camposExibidos.exibeIdadeGestacionalBiometriaFetalSemanas = true;
              laudoSelecionado.laudoJson.compativelIncompativel = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeIdadeGestacionalBiometriaFetalSemanas = false;
              laudoSelecionado.laudoJson.compativelIncompativel = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDataProvavelParto ? '' : 'text--disabled'
          }`"
        >
          <span>Data provável do parto:</span>
          <v-text-field
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeDataProvavelParto"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.dataProvavelParto"
            type="date"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDataProvavelParto"
            @click="
              camposExibidos.exibeDataProvavelParto = true;
              laudoSelecionado.laudoJson.dataProvavelParto = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDataProvavelParto = false;
              laudoSelecionado.laudoJson.dataProvavelParto = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeCrescimentoFetalUm ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Crescimento fetal adequado.',
              'Crescimento fetal abaixo do esperado para idade gestacional.',
              'Crescimento fetal acima do esperado para idade gestacional.',
            ]"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCrescimentoFetalUm"
            v-model="laudoSelecionado.laudoJson.crescimentoFetalUm"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeCrescimentoFetalUm"
            @click="
              camposExibidos.exibeCrescimentoFetalUm = true;
              laudoSelecionado.laudoJson.crescimentoFetalUm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeCrescimentoFetalUm = false;
              laudoSelecionado.laudoJson.crescimentoFetalUm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeExameLimitado ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Exame limitado devido ao aumento de ecogenecidade da parede abdominal materna.',
              '--------------------------------------------',
            ]"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeExameLimitado"
            v-model="laudoSelecionado.laudoJson.exameLimitado"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeExameLimitado"
            @click="
              camposExibidos.exibeExameLimitado = true;
              laudoSelecionado.laudoJson.exameLimitado = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeExameLimitado = false;
              laudoSelecionado.laudoJson.exameLimitado = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            required
            validate-on-blur
            filled
            outlined
            dense
            no-resize
            label="Comentários adicionais"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisQuatro"
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          class="d-flex justify-start align-center"
          :class="!camposExibidos.exibeobs ? 'text--disabled' : ''"
        >
          <span>
            OBS: No momento do exame não se observam anomalias morfológicas
            fetais, entretanto não podemos descartar malformações que não tem
            expressão ecográfica ou se apresentam de forma tardia (sensibilidade
            do exame 85%). A critério clínico, complementar avaliação
            cardiológica com especialista em ecocardiografia fetal.
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="!camposExibidos.exibeobs"
              @click="camposExibidos.exibeobs = true"
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="exibeobs"
              @click="camposExibidos.exibeobs = false"
              ><v-icon>mdi-eye</v-icon></v-btn
            >
          </span>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Doppler</h3>
        </v-col>
        <br />
        <br />
        <br />
       
        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Artéria Umbilical</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Índice de Pulsatilidade:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            @change="getIndicePulsatilidade"
            validate-on-blur
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical
            "
          ></v-text-field>
          <span>- Percentil</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical"
            class="mx-2 flex-grow-0"
            dense
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.percentil"
          ></v-text-field>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical"
            :items="['normal', 'alterado']"
            v-model="laudoSelecionado.laudoJson.indiceOpcao3"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>
          <span>para idade gestacional.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical"
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical = true
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical = false;
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical =
                undefined;
              laudoSelecionado.laudoJson.percentil = undefined;
              laudoSelecionado.laudoJson.indiceOpcao3 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDiastole ? '' : 'text--disabled'
          }`"
        >
          <span>Diástole:</span>
          <v-select
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeDiastole"
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :items="['presente', 'ausente', 'reversa']"
            v-model="laudoSelecionado.laudoJson.diastole"
          ></v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDiastole"
            @click="
              camposExibidos.exibeDiastole = true;
              laudoSelecionado.laudoJson.diastole = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDiastole = false;
              laudoSelecionado.laudoJson.diastole = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Artéria cerebral média</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Índice de Pulsatilidade:</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaCerebralMedia
            "
            required
            :disabled="
              !camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia
            "
            class="mx-2 flex-grow-0"
            dense
            @change="getACM"
            validate-on-blur
            v-mask="''"
          ></v-text-field>
          <span>- Percentil</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.percentil3"
            required
            :disabled="
              !camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia
            "
            validate-on-blur
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
          ></v-text-field>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="
              !camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia
            "
            :items="['normal', 'alterado']"
            v-model="laudoSelecionado.laudoJson.indiceOpcao1"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>
          <span>para idade gestacional.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia"
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia = true
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia = false;
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaCerebralMedia =
                undefined;
              laudoSelecionado.laudoJson.indiceOpcao1 = undefined;
              laudoSelecionado.laudoJson.percentil3 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeVelocidadePicoSistolico ? '' : 'text--disabled'
          }`"
        >
          <span>Velocidade de Pico Sistólica:</span>

          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.opcaoVelocidade"
            required
            :disabled="!camposExibidos.exibeVelocidadePicoSistolico"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-mask="''"
          ></v-text-field>
          <span
            required
            :disabled="!camposExibidos.exibeVelocidadePicoSistolico"
            validate-on-blur
          >
            cm/s, correspondendo a
          </span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.opcaoVelocidade2"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVelocidadePicoSistolico"
            v-mask="''"
          ></v-text-field>
          <span> múltiplos da mediana. </span>

          <!-- <v-select

          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeVelocidadePicoSistolico"
          :items="[
            ',sem sinais de anemia',
            ',sugerindo anemia leve',
            ',sugerindo anemia moderada',
            ',sugerindo anemia grave',
          ]"
          v-model="laudoSelecionado.laudoJson.opcaoVelocidade3"
          :clearable="podeEditar"
        ></v-select> -->
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeVelocidadePicoSistolico"
            @click="
              camposExibidos.exibeVelocidadePicoSistolico = true;
              laudoSelecionado.laudoJson.velocidadePicoSistolico = undefined;
              laudoSelecionado.laudoJson.opcaoVelocidade = undefined;
              laudoSelecionado.laudoJson.opcaoVelocidade2 = undefined;
              laudoSelecionado.laudoJson.opcaoVelocidade3 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeVelocidadePicoSistolico = false;
              laudoSelecionado.laudoJson.velocidadePicoSistolico = undefined;
              laudoSelecionado.laudoJson.opcaoVelocidade = undefined;
              laudoSelecionado.laudoJson.opcaoVelocidade2 = undefined;
              laudoSelecionado.laudoJson.opcaoVelocidade3 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Relação cérebro-placentária:</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="
              laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla
            "
            required
            :disabled="
              !camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla
            "
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-mask="''"
          ></v-text-field>
          <span>- Percentil</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.percentil2"
            required
            :disabled="
              !camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla
            "
            validate-on-blur
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
          ></v-text-field>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="
              !camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla
            "
            validate-on-blur
            :items="['normal', 'alterado']"
            v-model.number="laudoSelecionado.laudoJson.indiceOpcaoCerePlac"
            :clearable="podeEditar"
          ></v-select>
          <span>para idade gestacional.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla"
            @click="
              camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla = true
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla = false;
              laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla =
                undefined;
              laudoSelecionado.laudoJson.percentil2 = undefined;
              laudoSelecionado.laudoJson.indiceOpcaoCerePlac = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Artéria Uterina</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Índice de Pulsatilidade (direita):</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD"
            validate-on-blur
            v-mask="''"
          ></v-text-field>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD"
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD = true;
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD =
                undefined;
              laudoSelecionado.laudoJson.indiceOpcao2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD = false;
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD =
                undefined;
              laudoSelecionado.laudoJson.indiceOpcao2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Índice de Pulsatilidade (esquerda):</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
            "
            required
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE"
            dense
            validate-on-blur
            v-mask="''"
          ></v-text-field>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE"
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE = true;
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE =
                undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE = false;
              laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE =
                undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Índice de Pulsatilidade médio: </span>
          <span
            cols="12"
            :class="`pl-2 pr-2 d-flex justify-start align-center ${
              camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2
                ? ''
                : 'text--disabled'
            } `"
          >
            {{ mediaIndice }}
          </span>
          <span> - Percentil</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2"
            class="mx-2 flex-grow-0"
            dense
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.percentilArtMed"
          ></v-text-field>

          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2"
            validate-on-blur
            :items="['normal', 'alterado']"
            v-model="laudoSelecionado.laudoJson.indiceOpcaoArtMed"
            :clearable="podeEditar"
          ></v-select>
          <span>para idade gestacional.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2"
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2 = true
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2 = false;
              laudoSelecionado.laudoJson.percentilArtMed = undefined;
              laudoSelecionado.laudoJson.indiceOpcaoArtMed = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <!-- <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDuctoVenoso ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeDuctoVenoso"
            :items="['ducto Venoso', '----------------------------------']"
            v-model="laudoSelecionado.laudoJson.ductoVenoso"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>

          <span v-if="laudoSelecionado.laudoJson.ductoVenoso == 'Ducto Venoso'"
            >Índice de Pulsatilidade:</span
          >

          <v-text-field
            v-if="laudoSelecionado.laudoJson.ductoVenoso == 'Ducto Venoso'"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso"
            required
            :disabled="!camposExibidos.exibeDuctoVenoso"
            :clearable="podeEditar"
            validate-on-blur
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
          ></v-text-field>

          <v-select
            v-if="laudoSelecionado.laudoJson.ductoVenoso == 'Ducto Venoso'"
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            :disabled="!camposExibidos.exibeDuctoVenoso"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            :items="['normal', 'alterado']"
            v-model.number="laudoSelecionado.laudoJson.indiceOpcao4"
            :clearable="podeEditar"
          ></v-select>
          <span v-if="laudoSelecionado.laudoJson.ductoVenoso == 'Ducto Venoso'"
            >para idade gestacional.</span
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDuctoVenoso"
            @click="
              camposExibidos.exibeDuctoVenoso = true;
              laudoSelecionado.laudoJson.ductoVenoso = undefined;
              laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso =
                undefined;
              laudoSelecionado.laudoJson.indiceOpcao4 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDuctoVenoso = false;
              laudoSelecionado.laudoJson.ductoVenoso = undefined;
              laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso =
                undefined;
              laudoSelecionado.laudoJson.indiceOpcao4 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col> -->

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>
        <div class="pt-5">
          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkFluxoUmb"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Fluxo umbilical normal para a idade gestacional."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkAusFet"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Ausência de centralização fetal."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkRelCerNormal"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Relação cerebro-placentária normal para a idade gestacional."
            validate-on-blur
          ></v-checkbox>
          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkFluxoUmbRes"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Fluxo umbilical com resistência elevada."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkRelCerAcima"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Relação cerebro-placentária acima do percentil esperado para a idade gestacional. Convém monitorar."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkCentraFet"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Centralização fetal."
            validate-on-blur
          ></v-checkbox>
          <div class="d-flex justify-content-start align-items-center mt-n5">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.checkDucto"
              :readonly="carregamentoDoLaudo || !podeEditar"
              label="Ducto venoso "
              validate-on-blur
            ></v-checkbox>
            &nbsp;&nbsp;
            <v-select
              :readonly="carregamentoDoLaudo || !podeEditar"
              required
              validate-on-blur
              :disabled="!laudoSelecionado.laudoJson.checkDucto"
              class="d-flex justify-content-start align-self-center mx-2 flex-grow-0"
              dense
              :items="['normal', 'alterado']"
              v-model.number="laudoSelecionado.laudoJson.ductoVenNormAlt"
              :clearable="podeEditar"
            ></v-select>
          </div>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkIndiceAcima"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Indice de pulsatilidade médio acima do percentil esperado para a idade gestacional, aumentando o risco de pré-eclâmpsia e restrição de crescimento precoce."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkIndiceDentro"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Indice de pulsatilidade médio dentro do percentil normal para a idade gestacional, reduzindo o risco de pré-eclâmpsia e restrição de crescimento precoce."
            validate-on-blur
          ></v-checkbox>
          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkPicoAnemLeve"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia leve."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkPicoAnemMod"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia moderada."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkPicoGra"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia grave."
            validate-on-blur
          ></v-checkbox>
        </div>

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${camposExibidos.exibeFluxoUmbilicalOpcao1 ? '' : 'text--disabled'
        }`"
      >
        <span>Fluxo umbilical</span>
        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          :disabled="!camposExibidos.exibeFluxoUmbilicalOpcao1"
          :items="['normal', 'com resistência alterado']"
          v-model="laudoSelecionado.laudoJson.fluxoUmbilicalOpcao1"
          :clearable="podeEditar"
        ></v-select>
        <span>para a idade gestacional, com diástole</span>
        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exibeFluxoUmbilicalOpcao1"
          dense
          validate-on-blur
          :items="['positiva', 'zero', 'reversa']"
          v-model="laudoSelecionado.laudoJson.fluxoUmbilicalOpcao2"
          :clearable="podeEditar"
        ></v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exibeFluxoUmbilicalOpcao1"
          @click="camposExibidos.exibeFluxoUmbilicalOpcao1 = true;
          laudoSelecionado.laudoJson.fluxoUmbilicalOpcao1 = undefined;
          laudoSelecionado.laudoJson.fluxoUmbilicalOpcao2 = undefined;"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exibeFluxoUmbilicalOpcao1 = false;
          laudoSelecionado.laudoJson.fluxoUmbilicalOpcao1 = undefined;
          laudoSelecionado.laudoJson.fluxoUmbilicalOpcao2 = undefined;"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${camposExibidos.exibeCentralizacaoFetal ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeCentralizacaoFetal"
          :items="['Ausência', 'Presença']"
          v-model="laudoSelecionado.laudoJson.centralizacaoFetal"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span>de centralização fetal.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exibeCentralizacaoFetal"
          @click="camposExibidos.exibeCentralizacaoFetal = true;
          laudoSelecionado.laudoJson.centralizacaoFetal = undefined"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exibeCentralizacaoFetal = false;
          laudoSelecionado.laudoJson.centralizacaoFetal = undefined"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${camposExibidos.exibeRelacaoCerebroPlacentaria ? '' : 'text--disabled'
        }`"
      >
        <span>Relação cérebro-placentária</span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeRelacaoCerebroPlacentaria"
          class="mx-2 flex-grow-0"
          dense
          :items="['normal', 'alterado']"
          v-model.number="laudoSelecionado.laudoJson.relacaoCerebroPlacentaria"
          :clearable="podeEditar"
        ></v-select>
        <span>para idade gestacional.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exibeRelacaoCerebroPlacentaria"
          @click="camposExibidos.exibeRelacaoCerebroPlacentaria = true;
          laudoSelecionado.laudoJson.relacaoCerebroPlacentaria = undefined"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exibeRelacaoCerebroPlacentaria = false;
          laudoSelecionado.laudoJson.relacaoCerebroPlacentaria = undefined"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${camposExibidos.exibeDuctoVenosoIndice ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeDuctoVenosoIndice"
          class="mx-2 flex-grow-0"
          dense
          :items="[
            'Ducto venoso com Índice de Pulsatilidade',
            '----------------------------------',
          ]"
          v-model="laudoSelecionado.laudoJson.ductoVenosoIndice"
          :clearable="podeEditar"
        ></v-select>

        <v-select
          v-if="
            laudoSelecionado.laudoJson.ductoVenosoIndice ==
            'Ducto venoso com Índice de Pulsatilidade'
          "
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeDuctoVenosoIndice"
          class="mx-2 flex-grow-0"
          dense
          :items="['normal', 'alterado']"
          v-model.number="laudoSelecionado.laudoJson.indiceOpcao5"
          :clearable="podeEditar"
        ></v-select>
        <span
          v-if="
            laudoSelecionado.laudoJson.ductoVenosoIndice ==
            'Ducto venoso com Índice de Pulsatilidade'
          "
          >para idade gestacional.</span
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exibeDuctoVenosoIndice"
          @click="camposExibidos.exibeDuctoVenosoIndice = true;
          laudoSelecionado.laudoJson.ductoVenosoIndice = undefined;
          laudoSelecionado.laudoJson.indiceOpcao5 = undefined"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exibeDuctoVenosoIndice = false;
          laudoSelecionado.laudoJson.ductoVenosoIndice = undefined;
          laudoSelecionado.laudoJson.indiceOpcao5 = undefined"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${camposExibidos.exibeArteriasUterinas ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exibeArteriasUterinas"
          dense
          validate-on-blur
          :items="[
            'Artérias uterinas com média do Índice de Pulsatilidade',
            '----------------------------------',
          ]"
          v-model="laudoSelecionado.laudoJson.arteriasUterinas"
          :clearable="podeEditar"
        ></v-select>

        <v-select
          v-if="
            laudoSelecionado.laudoJson.arteriasUterinas ==
            'Artérias uterinas com média do Índice de Pulsatilidade'
          "
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exibeArteriasUterinas"
          dense
          :items="['normal', 'alterado']"
          v-model="laudoSelecionado.laudoJson.arteriasUterinas2"
          :clearable="podeEditar"
        ></v-select>
        <span
          v-if="
            laudoSelecionado.laudoJson.arteriasUterinas ==
            'Artérias uterinas com média do Índice de Pulsatilidade'
          "
          >para idade gestacional.</span
        >
        <v-select
          v-if="
            laudoSelecionado.laudoJson.arteriasUterinas ==
            'Artérias uterinas com média do Índice de Pulsatilidade'
          "
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exibeArteriasUterinas"
          validate-on-blur
          :items="['reduzindo', 'aumentando']"
          v-model.number="laudoSelecionado.laudoJson.arteriasUterinas3"
          :clearable="podeEditar"
        ></v-select>
        <span
          cols="12"
          class="d-flex justify-start align-center"
          v-if="
            laudoSelecionado.laudoJson.arteriasUterinas ==
            'Artérias uterinas com média do Índice de Pulsatilidade'
          "
          :disabled="!camposExibidos.exibeArteriasUterinas"
          >o risco de restrição de crescimento fetal e pré-eclâmpsia.</span
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exibeArteriasUterinas"
          @click="camposExibidos.exibeArteriasUterinas = true;
          laudoSelecionado.laudoJson.arteriasUterinas = undefined;
          laudoSelecionado.laudoJson.arteriasUterinas2 = undefined;
          laudoSelecionado.laudoJson.arteriasUterinas3 = undefined"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exibeArteriasUterinas = false;
          laudoSelecionado.laudoJson.arteriasUterinas = undefined;
          laudoSelecionado.laudoJson.arteriasUterinas2 = undefined;
          laudoSelecionado.laudoJson.arteriasUterinas3 = undefined"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${camposExibidos.exibeVelocidadeAcm ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeVelocidadeAcm"
          class="mx-2 flex-grow-0"
          dense
          :items="[
            'Velocidade máxima da ACM normal para a idade gestacional',
            'Velocidade máxima da ACM alterada para a idade gestacional',
            ' ',
          ]"
          v-model="laudoSelecionado.laudoJson.velocidadeAcm"
          :clearable="podeEditar"
        ></v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exibeVelocidadeAcm"
          @click="camposExibidos.exibeVelocidadeAcm = true;
          laudoSelecionado.laudoJson.velocidadeAcm = undefined"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exibeVelocidadeAcm = false;
          laudoSelecionado.laudoJson.velocidadeAcm = undefined"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            filled
            outlined
            full-width
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdicionais"
          ></v-textarea>
        </v-col>

        <!-- Perfil cervical -->
        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Perfil cervical</h3>
        </v-col>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColoUterino ? '' : 'text--disabled'
          }`"
        >
          <span>Colo uterino</span>
          <v-select
            :clearable="podeEditar"
            required
            :items="['fechado', 'entreaberto', 'aberto']"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloUterino"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino"
          ></v-select>
          <span>, com menor comprimento de</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloMedidaEmRepouco"
          >
          </v-text-field>
          <span>mm (colo avaliado por três minutos).</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeColoUterino"
            @click="camposExibidos.exibeColoUterino = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exibeColoUterino = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <!-- <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColoPassouAMedir ? '' : 'text--disabled'
          }`"
        >
          <span>Após três minutos de avaliação, o colo</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            :clearable="podeEditar"
            :items="['passou a medir', 'continuou a medir']"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloPassouAMedir"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoPassouAMedir"
          ></v-select>

          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.cmdois"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoPassouAMedir"
          ></v-text-field>
          <span>cm.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeColoPassouAMedir"
            @click="camposExibidos.exibeColoPassouAMedir = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exibeColoPassouAMedir = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col> -->

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeOrificioCervicalInterno ? '' : 'text--disabled'
          }`"
        >
          <span>Orifício cervical interno</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="['fechado', 'aberto']"
            required
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.orificioCervicalInterno"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOrificioCervicalInterno"
          ></v-select>

          <span
            v-if="
              laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            "
          >
            com
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.orificioCervicalInterno2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOrificioCervicalInterno"
          ></v-text-field>
          <span
            v-if="
              laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            "
          >
            mm
          </span>
          <span>.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeOrificioCervicalInterno"
            @click="camposExibidos.exibeOrificioCervicalInterno = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exibeOrificioCervicalInterno = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeProtusaoBolsaAmniotica ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="['Ausência', 'Presença']"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.protusaoBolsaAmniotica"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeProtusaoBolsaAmniotica"
          ></v-select>
          <span>de protrusão da bolsa amniótica no momento do exame.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeProtusaoBolsaAmniotica"
            @click="camposExibidos.exibeProtusaoBolsaAmniotica = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exibeProtusaoBolsaAmniotica = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSinalDeSludge ? '' : 'text--disabled'
          }`"
        >
          <span>Sinal de sludge</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="['presente', 'ausente']"
            required
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.sinalDeSludge"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSinalDeSludge"
          ></v-select>
          <span>.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeSinalDeSludge"
            @click="camposExibidos.exibeSinalDeSludge = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exibeSinalDeSludge = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibePolipoEndocervicalMedindo
              ? ''
              : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="[
              'Observamos pólipo endocervical medindo',
              '-------------------------------------',
            ]"
            required
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.polipoEndocervicalMedindo"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePolipoEndocervicalMedindo"
          ></v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.polipoEndocervicalMedindo ==
              'Observamos pólipo endocervical medindo'
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.polipoEndocervicalMedindoCm"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePolipoEndocervicalMedindo"
          ></v-text-field>
          <span
            v-if="
              laudoSelecionado.laudoJson.polipoEndocervicalMedindo ==
              'Observamos pólipo endocervical medindo'
            "
          >
            mm
          </span>
          <span>.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibePolipoEndocervicalMedindo"
            @click="camposExibidos.exibePolipoEndocervicalMedindo = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exibePolipoEndocervicalMedindo = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColoUterino2 ? '' : 'text--disabled'
          }`"
        >
          <span>Colo uterino</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            :clearable="podeEditar"
            :items="[
              'de aspecto ecográfico habitual para a idade gestacional',
              'entreaberto, porém, com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
              'entreaberto, com menos de 25 mm de comprimento',
              'fechado, com menos de 25 mm de comprimento',
            ]"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloUterino2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino2"
          ></v-select>
          <span>.</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeColoUterino2"
            @click="camposExibidos.exibeColoUterino2 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exibeColoUterino2 = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="
              laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical
            "
          >
          </v-textarea>
        </v-col>
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          class="ml-5"
          :loading="loadingImprimir"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          v-if="podePersonalizar"
          @click="adcionarConteudoEditorPersonalizado"
          class="ml-5"
        >
          {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],

  // beforeMount() {

  // },

  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      loadingImprimir: false,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      checkMamaDireita: false,
      exibeColoUterino: true,
      exibeColoPassouAMedir: true,
      exibeOrificioCervicalInterno: true,
      exibePolipoEndocervicalMedindo: true,
      exibeColoUterino2: true,
      exibeProtusaoBolsaAmniotica: true,
      exibeSinalDeSludge: true,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,
      camposExibidos: {
        exibeSituacao: true,
        exibeApresentacao: true,
        exibeDorsoFetal: true,
        exibeMovimentosFetaisPresentes: true,
        exibeBatimentosCardiacosFetais: true,
        exibeFrequenciaBpm: true,
        exibeDiametroBiparietal: true,
        exibeCircunferenciaCefalica: true,
        exibeCircunferenciaAbdominal: true,
        exibeComprimentoUmero: true,
        exibeComprimentoUlna: true,
        exibeComprimentoRadio: true,
        exibeComprimentoFemur: true,
        exibeComprimentoTibia: true,
        exibeComprimentoFibula: true,
        exibeComprimentoPe: true,
        exibeDiametroCerebelo: true,
        exibeCisternaMagna: true,
        exibeExameLimitado: true,
        exibePregaCutaneaOccipital: true,
        exibeAtrioVentriculoLateral: true,
        exibeOssoNasal: true,
        exibeDistanciaInterobitariaInterna: true,
        exibeDistanciaInterobitariaExterna: true,
        exibePesoFetal: true,
        exibeCranio: true,
        exibeEncefalo: true,
        exibeFace: true,
        exibeColuna: true,
        exibeTorax: true,
        exibeCoracao: true,
        exibeobs: true,
        exibeAbdomeUm: true,
        exibeSistemaUrinario: true,
        exibeDiametroLongitudinalRimDireito: true,
        exibeDiametroLongitudinalRimEsquerdo: true,
        exibePelveRenalDireita: true,
        exibePelveRenalEsquerda: true,
        exibeSexoGenitaliaExternaMorfologicamente: true,
        exibeMembros: true,
        exibeLiquidoAmniotico: true,
        exibeLocalizacao: true,
        exibeEspessura: true,
        exibeTextura: true,
        exibeGrauUm: true,
        exibeInsercao: true,
        exibeVasos: true,
        exibeIdadeGestacionalBiometriaFetalSemanas: true,
        exibeIdadeSemanas: true,
        exibeDiasFt: true,
        exibeDataProvavelParto: true,
        exibeCrescimentoFetalUm: true,

        exibeDiastole: true,
        exibeIndicePulsatilidadeArteriaUmbilical: true,
        exibeIndicePulsatilidadeArteriaCerebralMedia: true,
        exibeVelocidadePicoSistolico: true,
        exibeIndicePulsatilidadeRelacaoCerebroPla: true,
        exibeIndicePulsatilidadeArteriaUterinaD: true,
        exibeIndicePulsatilidadeArteriaUterinaE: true,
        exibeDuctoVenoso: true,
        exibeFluxoUmbilicalOpcao1: true,
        exibeCentralizacaoFetal: true,
        exibeRelacaoCerebroPlacentaria: true,
        exibeDuctoVenosoIndice: true,
        exibeArteriasUterinas: true,
        exibeVelocidadeAcm: true,
        exibeIndicePulsatilidadeArteriaUterinaE2: true,
        exibeColoUterino: true,
        exibeColoPassouAMedir: true,
        exibeOrificioCervicalInterno: true,
        exibePolipoEndocervicalMedindo: true,
        exibeColoUterino2: true,
        exibeProtusaoBolsaAmniotica: true,
        exibeSinalDeSludge: true,
        exibeIndicePulsatilidadeArteriaUterinaM: true,
        exibeIC: true,
        exibeflcc: true,
        exibeccca: true,
        exibeflca: true,
        exDataNascimento: true,
      },

      colodeutero: false,

      exibeSituacao: true,
      exibeApresentacao: true,
      exibeDorsoFetal: true,
      exibeMovimentosFetaisPresentes: true,
      exibeBatimentosCardiacosFetais: true,
      exibeFrequenciaBpm: true,
      exibeDiametroBiparietal: true,
      exibeCircunferenciaCefalica: true,
      exibeCircunferenciaAbdominal: true,
      exibeComprimentoUmero: true,
      exibeComprimentoUlna: true,
      exibeComprimentoRadio: true,
      exibeComprimentoFemur: true,
      exibeComprimentoTibia: true,
      exibeComprimentoFibula: true,
      exibeComprimentoPe: true,
      exibeDiametroCerebelo: true,
      exibeCisternaMagna: true,
      exibeExameLimitado: true,
      exibePregaCutaneaOccipital: true,
      exibeAtrioVentriculoLateral: true,
      exibeOssoNasal: true,
      exibeDistanciaInterobitariaInterna: true,
      exibeDistanciaInterobitariaExterna: true,
      exibePesoFetal: true,
      exibeCranio: true,
      exibeEncefalo: true,
      exibeFace: true,
      exibeColuna: true,
      exibeTorax: true,
      exibeCoracao: true,
      exibeobs: true,
      exibeAbdomeUm: true,
      exibeSistemaUrinario: true,
      exibeDiametroLongitudinalRimDireito: true,
      exibeDiametroLongitudinalRimEsquerdo: true,
      exibePelveRenalDireita: true,
      exibePelveRenalEsquerda: true,
      exibeSexoGenitaliaExternaMorfologicamente: true,
      exibeMembros: true,
      exibeLiquidoAmniotico: true,
      exibeLocalizacao: true,
      exibeEspessura: true,
      exibeTextura: true,
      exibeGrauUm: true,
      exibeInsercao: true,
      exibeVasos: true,
      exibeIdadeGestacionalBiometriaFetalSemanas: true,
      exibeIdadeSemanas: true,
      exibeDiasFt: true,
      exibeDataProvavelParto: true,
      exibeCrescimentoFetalUm: true,

      exibeDiastole: true,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,
      exibeIndicePulsatilidadeArteriaUmbilical: true,
      exibeIndicePulsatilidadeArteriaCerebralMedia: true,
      exibeVelocidadePicoSistolico: true,
      exibeIndicePulsatilidadeRelacaoCerebroPla: true,
      exibeIndicePulsatilidadeArteriaUterinaD: true,
      exibeIndicePulsatilidadeArteriaUterinaE: true,
      exibeIndicePulsatilidadeArteriaUterinaE2: true,
      exibeDuctoVenoso: true,
      exibeFluxoUmbilicalOpcao1: true,
      exibeCentralizacaoFetal: true,
      exibeRelacaoCerebroPlacentaria: true,
      exibeDuctoVenosoIndice: true,
      exibeIndicePulsatilidadeArteriaUterinaM: true,
      exibeArteriasUterinas: true,
      exibeVelocidadeAcm: true,
      exibeIC: true,
        exibeflcc: true,
        exibeccca: true,
        exibeflca: true,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 25 mm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    coracao(){
      return this.laudoSelecionado.laudoJson.coracao  || []
    },
    cranio(){
      return this.laudoSelecionado.laudoJson.cranio  || []
    },
    encefalo(){
      return this.laudoSelecionado.laudoJson.encefalo || []
    },
    face(){
      return this.laudoSelecionado.laudoJson.face || []
    },
    torax(){
      return this.laudoSelecionado.laudoJson.torax || []
    },
    abdomeUm(){
      return this.laudoSelecionado.laudoJson.abdomeUm || []
    },
    sistemaUrinario(){
      return this.laudoSelecionado.laudoJson.sistemaUrinario || []
    },
    coluna(){
      return this.laudoSelecionado.laudoJson.coluna || []
    },
    membros(){
      return this.laudoSelecionado.laudoJson.membros || []
    },
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    mediaIndice() {
      if (
        !this.laudoSelecionado ||
        !this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD ||
        !this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
      )
        return '';
      let media = (
        (parseFloat(
          this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD
        ) +
          parseFloat(
            this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
          )) /
        2.0
      ).toFixed(2);

      let semanas;
      if (
        !!this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
      ) {
        semanas = parseInt(
          this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
        );
      } else {
        return media;
      }

      if (semanas >= 11 && semanas <= 41) {
        let valores = this.getIMP(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (media - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudoSelecionado.laudoJson.percentilArtMed = resultado;
      } else {
        this.laudoSelecionado.laudoJson.percentilArtMed = '';
      }

      return media;
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },

    getIMP(valSemanas) {
      let tabelaIndices = {};
      tabelaIndices[11] = [1.18, 2.7];
      tabelaIndices[12] = [1.11, 2.53];
      tabelaIndices[13] = [1.05, 2.38];
      tabelaIndices[14] = [0.99, 2.24];
      tabelaIndices[15] = [0.94, 2.11];
      tabelaIndices[16] = [0.89, 1.99];
      tabelaIndices[17] = [0.85, 1.88];
      tabelaIndices[18] = [0.81, 1.79];
      tabelaIndices[19] = [0.78, 1.7];
      tabelaIndices[20] = [0.74, 1.61];
      tabelaIndices[21] = [0.71, 1.54];
      tabelaIndices[22] = [0.69, 1.47];
      tabelaIndices[23] = [0.66, 1.41];
      tabelaIndices[24] = [0.64, 1.35];
      tabelaIndices[25] = [0.62, 1.3];
      tabelaIndices[26] = [0.6, 1.25];
      tabelaIndices[27] = [0.58, 1.21];
      tabelaIndices[28] = [0.56, 1.17];
      tabelaIndices[29] = [0.55, 1.13];
      tabelaIndices[30] = [0.54, 1.1];
      tabelaIndices[31] = [0.52, 1.06];
      tabelaIndices[32] = [0.51, 1.04];
      tabelaIndices[33] = [0.5, 1.01];
      tabelaIndices[34] = [0.5, 0.99];
      tabelaIndices[35] = [0.49, 0.97];
      tabelaIndices[36] = [0.48, 0.95];
      tabelaIndices[37] = [0.48, 0.94];
      tabelaIndices[38] = [0.47, 0.92];
      tabelaIndices[39] = [0.47, 0.91];
      tabelaIndices[40] = [0.47, 0.9];
      tabelaIndices[41] = [0.47, 0.89];

      return tabelaIndices[valSemanas];
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let todos = [];

      this.conteudoEditorPersonalizado = [];

      if (this.laudoSelecionado.laudoJson.situacao) {
        todos.push(`<h4>Estática</h4>`);
      }

      if (
        this.camposExibidos.exibeSituacao &&
        this.laudoSelecionado.laudoJson.situacao
      ) {
        todos.push(`Situação: ${this.laudoSelecionado.laudoJson.situacao}.\n`);
      }
      if (
        this.camposExibidos.exibeApresentacao &&
        this.laudoSelecionado.laudoJson.apresentacao
      ) {
        todos.push(
          `Apresentação: ${this.laudoSelecionado.laudoJson.apresentacao}.\n`
        );
      }
      if (
        this.camposExibidos.exibeDorsoFetal &&
        this.laudoSelecionado.laudoJson.dorsoFetal
      ) {
        todos.push(
          `Dorso fetal: ${this.laudoSelecionado.laudoJson.dorsoFetal}.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.movimentosFetaisPresentes) {
        todos.push(`\n<h4>Vitalidade</h4>`);
      }
      if (
        this.camposExibidos.exibeMovimentosFetaisPresentes &&
        this.laudoSelecionado.laudoJson.movimentosFetaisPresentes
      ) {
        todos.push(
          `Movimentos fetais: ${this.laudoSelecionado.laudoJson.movimentosFetaisPresentes}.\n`
        );
      }
      if (
        this.camposExibidos.exibeBatimentosCardiacosFetais &&
        this.laudoSelecionado.laudoJson.batimentosCardiacosFetais
      ) {
        todos.push(
          `Batimentos cardiacos fetais: ${this.laudoSelecionado.laudoJson.batimentosCardiacosFetais}.\n`
        );
      }
      if (
        this.camposExibidos.exibeFrequenciaBpm &&
        this.laudoSelecionado.laudoJson.frequenciaBpm
      ) {
        todos.push(
          `Frequência de ${this.laudoSelecionado.laudoJson.frequenciaBpm} bpm.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdcionais001) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionais001}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.diametroBiparietal) {
        todos.push(`\n<h4>Biometria fetal</h4>`);
      }
      if (
      (this.camposExibidos.exibeDiametroBiparietal &&
        this.laudoSelecionado.laudoJson.diametroBiparietal) ||  this.laudoSelecionado.laudoJson.flcc
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.diametroBiparietal?
          'Diâmetro biparietal: ' + this.laudoSelecionado.laudoJson.diametroBiparietal + ' mm.                       '
          :
          '                                                                       '
          
          }${this.laudoSelecionado.laudoJson.flcc && this.laudoSelecionado.laudoJson.diametroBiparietal?'      ':''}${this.laudoSelecionado.laudoJson.flcc? 
            'FL/CC: ' + this.laudoSelecionado.laudoJson.flcc +' %'
            :

            ''
          }\n`
        );
      }
      if (
        (this.camposExibidos.exibeCircunferenciaCefalica &&
        this.laudoSelecionado.laudoJson.circunferenciaCefalica) ||this.laudoSelecionado.laudoJson.ccca
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.circunferenciaCefalica?
          'Circunferência cefálica: ' + this.laudoSelecionado.laudoJson.circunferenciaCefalica + ' mm.'
          :
          '                                                                       '
          
          }${this.laudoSelecionado.laudoJson.ccca? 
            '                       CC/CA: ' + this.laudoSelecionado.laudoJson.ccca +' %'
            :

            ''
          }\n`
        );
      }
      if (
        (this.camposExibidos.exibeCircunferenciaAbdominal &&
        this.laudoSelecionado.laudoJson.circunferenciaAbdominal) ||this.laudoSelecionado.laudoJson.flca
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.circunferenciaAbdominal?
          'Circunferência abdominal: ' + this.laudoSelecionado.laudoJson.circunferenciaAbdominal + ' mm.                   '
          :
          '                                                                       '
          
          }${this.laudoSelecionado.laudoJson.flca && this.laudoSelecionado.laudoJson.circunferenciaAbdominal?'':''}${this.laudoSelecionado.laudoJson.flca? 
            'FL/CA: ' + this.laudoSelecionado.laudoJson.flca +' %'
            :

            ''
          }\n`
        );
      }
      if (
        (this.camposExibidos.exibeComprimentoUmero &&
        this.laudoSelecionado.laudoJson.comprimentoUmero) ||this.laudoSelecionado.laudoJson.ic
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comprimentoUmero?
          'Comprimento do úmero: ' + this.laudoSelecionado.laudoJson.comprimentoUmero + ' mm.                      '
          :
          '                                                                        '
          
          }${this.laudoSelecionado.laudoJson.ic && this.laudoSelecionado.laudoJson.comprimentoUmero?'':''}${this.laudoSelecionado.laudoJson.ic? 
            'IC: ' + this.laudoSelecionado.laudoJson.ic +' %'
            :

            ''
          }\n`
        );
      }
      if (
        this.camposExibidos.exibeComprimentoUlna &&
        this.laudoSelecionado.laudoJson.comprimentoUlna
      ) {
        todos.push(
          `Comprimento da ulna: ${this.laudoSelecionado.laudoJson.comprimentoUlna} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeComprimentoRadio &&
        this.laudoSelecionado.laudoJson.comprimentoRadio
      ) {
        todos.push(
          `Comprimento do rádio: ${this.laudoSelecionado.laudoJson.comprimentoRadio} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeComprimentoFemur &&
        this.laudoSelecionado.laudoJson.comprimentoFemur
      ) {
        todos.push(
          `Comprimento do fêmur: ${this.laudoSelecionado.laudoJson.comprimentoFemur} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeComprimentoTibia &&
        this.laudoSelecionado.laudoJson.comprimentoTibia
      ) {
        todos.push(
          `Comprimento da tíbia: ${this.laudoSelecionado.laudoJson.comprimentoTibia} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeComprimentoFibula &&
        this.laudoSelecionado.laudoJson.comprimentoFibula
      ) {
        todos.push(
          `Comprimento da fíbula: ${this.laudoSelecionado.laudoJson.comprimentoFibula} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeComprimentoPe &&
        this.laudoSelecionado.laudoJson.comprimentoPe
      ) {
        todos.push(
          `Comprimento do pé: ${this.laudoSelecionado.laudoJson.comprimentoPe} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeDiametroCerebelo &&
        this.laudoSelecionado.laudoJson.diametroCerebelo
      ) {
        todos.push(
          `Diâmetro do cerebelo: ${this.laudoSelecionado.laudoJson.diametroCerebelo} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeCisternaMagna &&
        this.laudoSelecionado.laudoJson.cisternaMagna
      ) {
        todos.push(
          `Cisterna magna: ${this.laudoSelecionado.laudoJson.cisternaMagna} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibePregaCutaneaOccipital &&
        this.laudoSelecionado.laudoJson.pregaCutaneaOccipital
      ) {
        todos.push(
          `Prega cutânea occipital: ${this.laudoSelecionado.laudoJson.pregaCutaneaOccipital} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeAtrioVentriculoLateral &&
        this.laudoSelecionado.laudoJson.atrioVentriculoLateral
      ) {
        todos.push(
          `Átrio do ventrículo lateral: ${this.laudoSelecionado.laudoJson.atrioVentriculoLateral} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeOssoNasal &&
        this.laudoSelecionado.laudoJson.ossoNasal
      ) {
        todos.push(
          `Osso Nasal: ${this.laudoSelecionado.laudoJson.ossoNasal} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeDistanciaInterobitariaInterna &&
        this.laudoSelecionado.laudoJson.distanciaInterobitariaInterna
      ) {
        todos.push(
          `Distância interorbitária interna: ${this.laudoSelecionado.laudoJson.distanciaInterobitariaInterna} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeDistanciaInterobitariaExterna &&
        this.laudoSelecionado.laudoJson.distanciaInterobitariaExterna
      ) {
        todos.push(
          `Distância interorbitária externa: ${this.laudoSelecionado.laudoJson.distanciaInterobitariaExterna} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibePesoFetal &&
        this.laudoSelecionado.laudoJson.pesoFetal
      ) {
        todos.push(
          `Peso fetal: ${this.laudoSelecionado.laudoJson.pesoFetal} mm.\n`
        );
      }

      if (this.camposExibidos.exibeCranio) {
        todos.push(`\n<h4>Morfologia fetal</h4>`);
      }

      if (
        this.camposExibidos.exibeCranio &&
        this.laudoSelecionado.laudoJson.cranio
      ) {
        
        let texto2 = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.cranio.length; i++) {
          texto2 += ((i==0? (this.laudoSelecionado.laudoJson.cranio[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaiscranio : this.laudoSelecionado.laudoJson.cranio[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.cranio[i]== 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisCranio : 
          (this.laudoSelecionado.laudoJson.cranio[i] == 'falha de continuidade no crânio, na região'?
          this.laudoSelecionado.laudoJson.cranio[i] +  ' ' +  this.laudoSelecionado.laudoJson.cranioFalta[i] + ' com protrusão de tecido nervoso e meninges':this.laudoSelecionado.laudoJson.cranio[i])
          
          ) )+(i+1 == this.laudoSelecionado.laudoJson.cranio.length? '.' : ', '))
         
        }
        todos.push(`<span><strong>Crânio:</strong> ${texto2}</span>`);
        
        
      }
      if (
        this.camposExibidos.exibeEncefalo &&
        this.laudoSelecionado.laudoJson.encefalo
      ) {
      
        
        let texto3 = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.encefalo.length; i++) {
          texto3 += ((i==0? (this.laudoSelecionado.laudoJson.encefalo[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisencefalo : this.laudoSelecionado.laudoJson.encefalo[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.encefalo[i]== 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisEncefalo : 
          (this.laudoSelecionado.laudoJson.encefalo[i] == 'presença de cistos de plexo coroide, medindo'
          ||  this.laudoSelecionado.laudoJson.encefalo[i] == 'átrio dos ventrículos laterais dilatados, medindo '?
          this.laudoSelecionado.laudoJson.encefalo[i]+ this.laudoSelecionado.laudoJson.encefaloMedindo + ' mm.'
          :
          this.laudoSelecionado.laudoJson.encefalo[i]
          )
          ) )+(i+1 == this.laudoSelecionado.laudoJson.encefalo.length? '.' : ', '))
         
        }
        todos.push(`<span><strong>Encéfalo:</strong> ${texto3}</span>`);
        
      }
      if (
        this.camposExibidos.exibeFace &&
        this.laudoSelecionado.laudoJson.face
      ) {
        
        let texto4 = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.face.length; i++) {
          texto4 += ((i==0? (this.laudoSelecionado.laudoJson.face[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisFace : this.laudoSelecionado.laudoJson.face[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.face[i]== 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisFace : 
          
          this.laudoSelecionado.laudoJson.face[i]
          
          ) )+(i+1 == this.laudoSelecionado.laudoJson.face.length? '.' : ', '))
         
        }
        todos.push(`<span><strong>Face:</strong> ${texto4}</span>`);
        
      }
      if (
        this.camposExibidos.exibeColuna &&
        this.laudoSelecionado.laudoJson.coluna
      ) {
       
        let texto5 = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.coluna.length; i++) {
          texto5 += ((i==0? (this.laudoSelecionado.laudoJson.coluna[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisColuna : this.laudoSelecionado.laudoJson.coluna[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.coluna[i]== 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisColuna : 
          
          this.laudoSelecionado.laudoJson.coluna[i]
          
          ) )+(i+1 == this.laudoSelecionado.laudoJson.coluna.length? '.' : ', '))
         
        }
        todos.push(`<span><strong>Coluna:</strong> ${texto5}</span>`);
        
        
      }
      if (
        this.camposExibidos.exibeTorax &&
        this.laudoSelecionado.laudoJson.torax
      ) {
        
        let texto6 = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.torax.length; i++) {
          texto6 += ((i==0? (this.laudoSelecionado.laudoJson.torax[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisTorax : this.laudoSelecionado.laudoJson.torax[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.torax[i]== 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisTorax : 
          
          this.laudoSelecionado.laudoJson.torax[i]
          
          ) )+(i+1 == this.laudoSelecionado.laudoJson.torax.length? '.' : ', '))
         
        }
        todos.push(`<span><strong>Tórax:</strong> ${texto6}</span>`);
       
      }

      if (this.laudoSelecionado.laudoJson.coracao) {
        let texto = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.coracao.length; i++) {
          texto += ((i==0? (this.laudoSelecionado.laudoJson.coracao[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisCoracao : this.laudoSelecionado.laudoJson.coracao[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.coracao[i]== 'outro'? this.laudoSelecionado.laudoJson.comentariosAdcionaisCoracao : this.laudoSelecionado.laudoJson.coracao[i]) )+(i+1 == this.laudoSelecionado.laudoJson.coracao.length? '.' : '. '))
        }
        todos.push(`<span><strong>Coração:</strong> ${texto}</span>`);
        

        
      }
      if (
        this.camposExibidos.exibeAbdomeUm &&
        this.laudoSelecionado.laudoJson.abdomeUm
      ) {
        
        let texto6 = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.abdomeUm.length; i++) {
          texto6 += ((i==0? (this.laudoSelecionado.laudoJson.abdomeUm[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.abdomeOutro : this.laudoSelecionado.laudoJson.abdomeUm[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.abdomeUm[i]== 'outro'? this.laudoSelecionado.laudoJson.abdomeOutro : 
          
          this.laudoSelecionado.laudoJson.abdomeUm[i]
          
          ) )+(i+1 == this.laudoSelecionado.laudoJson.abdomeUm.length? '.' : ', '))
         
        }
        todos.push(`<span><strong>Abdômen:</strong> ${texto6}</span>`);
        
      }
      if (
        this.camposExibidos.exibeSistemaUrinario &&
        this.laudoSelecionado.laudoJson.sistemaUrinario
      ) {
       
        let texto6 = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.sistemaUrinario.length; i++) {
          texto6 += ((i==0? (this.laudoSelecionado.laudoJson.sistemaUrinario[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.sistemaUrinarioOutro : this.laudoSelecionado.laudoJson.sistemaUrinario[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.sistemaUrinario[i]== 'outro'? this.laudoSelecionado.laudoJson.sistemaUrinarioOutro : 
          
          this.laudoSelecionado.laudoJson.sistemaUrinario[i]
          
          ) )+(i+1 == this.laudoSelecionado.laudoJson.sistemaUrinario.length? '.' : ', '))
         
        }
        todos.push(`<span><strong>Sistema urinário:</strong> ${texto6}</span>`);
        
        
      }

      if (
        this.camposExibidos.exibeDiametroLongitudinalRimDireito &&
        this.laudoSelecionado.laudoJson.diametroLongitudinalRimDireito
      ) {
        todos.push(
          `Diâmetro longitudinal do rim direito: ${this.laudoSelecionado.laudoJson.diametroLongitudinalRimDireito} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeDiametroLongitudinalRimEsquerdo &&
        this.laudoSelecionado.laudoJson.diametroLongitudinalRimEsquerdo
      ) {
        todos.push(
          `Diâmetro longitudinal do rim esquerdo: ${this.laudoSelecionado.laudoJson.diametroLongitudinalRimEsquerdo} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibePelveRenalDireita &&
        this.laudoSelecionado.laudoJson.pelveRenalDireita
      ) {
        todos.push(
          `Pelve renal direita: ${this.laudoSelecionado.laudoJson.pelveRenalDireita} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibePelveRenalEsquerda &&
        this.laudoSelecionado.laudoJson.pelveRenalEsquerda
      ) {
        todos.push(
          `Pelve renal esquerda: ${this.laudoSelecionado.laudoJson.pelveRenalEsquerda} mm.\n`
        );
      }
      if (
        this.camposExibidos.exibeSexoGenitaliaExternaMorfologicamente &&
        this.laudoSelecionado.laudoJson.sexoGenitaliaExternaMorfologicamente
      ) {
        todos.push(
          `<span><strong>Sexo - </strong>genitália externa morfologicamente ${this.laudoSelecionado.laudoJson.sexoGenitaliaExternaMorfologicamente}.</span>`
        );
      }

      if (
        this.camposExibidos.exibeMembros &&
        this.laudoSelecionado.laudoJson.membros
      ) {
        
        let texto7 = ''
        for(let i = 0; i<this.laudoSelecionado.laudoJson.membros.length; i++) {
          texto7 += ((i==0? (this.laudoSelecionado.laudoJson.membros[i].toLowerCase() == 'outro'? this.laudoSelecionado.laudoJson.membrosOutro : this.laudoSelecionado.laudoJson.membros[i].toLowerCase())
          :(this.laudoSelecionado.laudoJson.membros[i]== 'outro'? this.laudoSelecionado.laudoJson.membrosOutro : 
          
          this.laudoSelecionado.laudoJson.membros[i]
          
          ) )+(i+1 == this.laudoSelecionado.laudoJson.membros.length? '.' : ', '))
         
        }
        todos.push(`<span><strong>Membros:</strong> ${texto7}</span>`);
      }

      if (
        this.camposExibidos.exibeLiquidoAmniotico &&
        this.laudoSelecionado.laudoJson.liquidoAmniotico
      ) {
        todos.push(
          `<span><strong>Líquido amniótico: </strong>${this.laudoSelecionado.laudoJson.liquidoAmniotico}.</span>`
        );
        if (
          this.laudoSelecionado.laudoJson.liquidoAmniotico === 'outro' &&
          this.laudoSelecionado.laudoJson.liquidoAmnioticoOutro
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.liquidoAmnioticoOutro}.`
          );
        }
        
      }

      if (this.laudoSelecionado.laudoJson.liquidoAmnioticoOutro2) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.liquidoAmnioticoOutro2}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.localizacao) {
        todos.push(`\n<h4>Placenta</h4>`);
      }

      if (
        this.camposExibidos.exibeLocalizacao &&
        this.laudoSelecionado.laudoJson.localizacao
      ) {
        todos.push(
          `Localização: ${this.laudoSelecionado.laudoJson.localizacao}.`
        );
        if (
          this.laudoSelecionado.laudoJson.localizacao === 'outro' &&
          this.laudoSelecionado.laudoJson.localizacaoOutro
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.localizacaoOutro}.`);
        }
        todos.push(`\n`);
      }

      if (
        this.camposExibidos.exibeEspessura &&
        this.laudoSelecionado.laudoJson.espessura
      ) {
        todos.push(
          `Espessura: ${this.laudoSelecionado.laudoJson.espessura} mm.\n`
        );
      }

      if (
        this.camposExibidos.exibeTextura &&
        this.laudoSelecionado.laudoJson.textura
      ) {
        todos.push(`Textura: ${this.laudoSelecionado.laudoJson.textura}.\n`);
      }
      if (
        this.camposExibidos.exibeGrauUm &&
        this.laudoSelecionado.laudoJson.grauUm
      ) {
        todos.push(`Grau: ${this.laudoSelecionado.laudoJson.grauUm}.\n`);
      }

      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisDois) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisDois}\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.insercao) {
        todos.push(`\n<h4>Cordão umbilical</h4>`);
      }

      if (
        this.camposExibidos.exibeInsercao &&
        this.laudoSelecionado.laudoJson.insercao
      ) {
        todos.push(`Inserção: ${this.laudoSelecionado.laudoJson.insercao}.\n`);
      }
      if (
        this.camposExibidos.exibeVasos &&
        this.laudoSelecionado.laudoJson.vasos
      ) {
        todos.push(`Vasos: ${this.laudoSelecionado.laudoJson.vasos} mm.\n`);
      }

      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisTres) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisTres}\n`
        );
      }

      todos.push(`\n<h4>Conclusão morfológica</h4>\n`);

      if (this.camposExibidos.exibeIdadeSemanas) {
        todos.push(`Gestação única com feto vivo.\n`);
      }

      if (this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas)
        todos.push(
          `Idade gestacional baseado na biometria fetal atual de ${
            this.laudoSelecionado.laudoJson
              .idadeGestacionalBiometriaFetalSemanas
              ? this.laudoSelecionado.laudoJson
                  .idadeGestacionalBiometriaFetalSemanas > 1
                ? this.laudoSelecionado.laudoJson
                    .idadeGestacionalBiometriaFetalSemanas + ' semanas e '
                : this.laudoSelecionado.laudoJson
                    .idadeGestacionalBiometriaFetalSemanas + ' semana e '
              : ''
          }${
            this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias
              ? this.laudoSelecionado.laudoJson
                  .idadeGestacionalBiometriaFetalDias > 1
                ? this.laudoSelecionado.laudoJson
                    .idadeGestacionalBiometriaFetalDias + ' dias '
                : this.laudoSelecionado.laudoJson
                    .idadeGestacionalBiometriaFetalDias + ' dia '
              : ''
          }${
            this.laudoSelecionado.laudoJson.compativelIncompativel
              ? this.laudoSelecionado.laudoJson.compativelIncompativel
              : ''
          }\n`
        );

      // if (
      //   this.camposExibidos.exibeIdadeSemanas &&
      //   this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
      // ) {
      //   todos.push(
      //     `Idade gestacional baseado na biometria fetal atual de ${this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas} semanas e ${this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias} dias, ${this.laudoSelecionado.laudoJson.compativelIncompativel}\n`
      //   );
      // }
      if (
        this.camposExibidos.exibeDataProvavelParto &&
        this.laudoSelecionado.laudoJson.dataProvavelParto
      ) {
        todos.push(
          `Data provável do parto: ${this.dataFormatada(
            this.laudoSelecionado.laudoJson.dataProvavelParto
          )}\n`
        );
      }
      if (
        this.camposExibidos.exibeCrescimentoFetalUm &&
        this.laudoSelecionado.laudoJson.crescimentoFetalUm
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.crescimentoFetalUm}\n`);
      }
      if (
        this.camposExibidos.exibeExameLimitado &&
        this.laudoSelecionado.laudoJson.exameLimitado
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.exameLimitado}\n`);
      }

      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisQuatro) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisQuatro}\n`
        );
      }
      todos.push(`\n`);
      if (this.camposExibidos.exibeobs) {
        todos.push(
          `<span style="font-size: 10pt">OBS: No momento do exame não se observam anomalias morfológicas fetais, entretanto não podemos descartar malformações que não tem expressão ecográfica ou se apresentam de forma tardia (sensibilidade do exame 85%). A critério clínico, complementar avaliação cardiológica com especialista em ecocardiografia fetal. </span>\n`
        );
      }


      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);


    

      if (this.camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical) {
        todos.push(`<h4>Doppler</h4>`);
      }
      todos.push(`\n`);
       
      // todos.push(`\n<h4>História materna </h4>`)
      //   if (this.laudoSelecionado.laudoJson.peso) {
      //     todos.push(`${this.laudoSelecionado.laudoJson.peso?"Peso: " + this.laudoSelecionado.laudoJson.peso + ' kg.':""}${
      // this.laudoSelecionado.laudoJson.altura?" Altura: " + this.laudoSelecionado.laudoJson.altura + ' cm.':""}${
      // this.laudoSelecionado.laudoJson.imc?" IMC: " + this.laudoSelecionado.laudoJson.imc :""}.\n`);
      //   }
      //   if (this.laudoSelecionado.laudoJson.origemRacial)
      //   todos.push(`Origem racial: ${this.laudoSelecionado.laudoJson.origemRacial}.\n`);
      //   if (this.laudoSelecionado.laudoJson.metodoConcepcao)
      //   todos.push(`Método de concepção: ${this.laudoSelecionado.laudoJson.metodoConcepcao}.\n`);
      //   if (this.laudoSelecionado.laudoJson.fuma)
      //   todos.push(`Fumar durante a gravidez: ${this.laudoSelecionado.laudoJson.fuma}.\n`);
      //   if (this.laudoSelecionado.laudoJson.hipertensao)
      //   todos.push(`Hipertensão crônica: ${this.laudoSelecionado.laudoJson.hipertensao}.\n`);
      //   if (this.laudoSelecionado.laudoJson.diabeteTipoUm)
      //   todos.push(`Diabetes tipo I: ${this.laudoSelecionado.laudoJson.diabeteTipoUm}.\n`);
      //   if (this.laudoSelecionado.laudoJson.diabeteTipoDois)
      //   todos.push(`Diabetes tipo II: ${this.laudoSelecionado.laudoJson.diabeteTipoDois}.\n`);
      //   if (this.laudoSelecionado.laudoJson.lupus)
      //   todos.push(`Lúpus eritematoso sistêmico:  ${this.laudoSelecionado.laudoJson.lupus}.\n`);
      //   if (this.laudoSelecionado.laudoJson.lupus)
      //   todos.push(`Síndrome antifosfolipídica:  ${this.laudoSelecionado.laudoJson.sindromeAnti}.\n`);
      //   if (this.laudoSelecionado.laudoJson.historicoFamiliarDiabetes)
      //   todos.push(`História familiar de diabetes:  ${this.laudoSelecionado.laudoJson.historicoFamiliarDiabetes}.`);
      //   if(this.laudoSelecionado.laudoJson.historiaObstetricaAnterior){

        
      //   if(this.laudoSelecionado.laudoJson.historiaObstetricaAnterior.length>0)
      //   todos.push("\n<h4>História obstétrica anterior</h4>")
      //   for (let j = 0; j < this.laudoSelecionado.laudoJson.historiaObstetricaAnterior.length; j++) {
      //     todos.push(this.laudoSelecionado.laudoJson.historiaObstetricaAnterior[j]+ '.\n');
      //   }
      // }
      // if(this.laudoSelecionado.laudoJson.perdasGestacionais){

      
      //   if(this.laudoSelecionado.laudoJson.perdasGestacionais.length>0)
      //   todos.push("\n<h4>Perdas gestacionais</h4>")
      //   for (let k = 0; k < this.laudoSelecionado.laudoJson.perdasGestacionais.length; k++) {
      //     todos.push(this.laudoSelecionado.laudoJson.perdasGestacionais[k] + '.\n');
         
      //   }
      // }
      if (this.camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical) {
        todos.push(`<h4>Artéria umbilical</h4>`);
      }

      if (this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical)
        todos.push(
          `Índice de Pulsatilidade: ${
            this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical
          }${
            this.laudoSelecionado.laudoJson.percentil
              ? ' - Percentil ' + this.laudoSelecionado.laudoJson.percentil + ''
              : ''
          }${
            this.laudoSelecionado.laudoJson.indiceOpcao3
              ? ' ' +
                this.laudoSelecionado.laudoJson.indiceOpcao3 +
                ' para idade gestacional'
              : ''
          }.\n`
        );
      if (
        this.camposExibidos.exibeDiastole &&
        this.laudoSelecionado.laudoJson.diastole
      ) {
        todos.push(`Diástole ${this.laudoSelecionado.laudoJson.diastole}\n`);
      }
      todos.push(`\n`);

      if (this.camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia) {
        todos.push(`\n<h4>Artéria cerebral média</h4>`);
      }

      if (
        this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaCerebralMedia
      )
        todos.push(
          `Índice de Pulsatilidade: ${
            this.laudoSelecionado.laudoJson
              .indicePulsatilidadeArteriaCerebralMedia
          }${
            this.laudoSelecionado.laudoJson.percentil3
              ? ' - Percentil ' +
                this.laudoSelecionado.laudoJson.percentil3 +
                ''
              : ''
          }${
            this.laudoSelecionado.laudoJson.indiceOpcao1
              ? ' ' +
                this.laudoSelecionado.laudoJson.indiceOpcao1 +
                ' para idade gestacional'
              : ''
          }.\n`
        );
      if (this.laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla)
        todos.push(
          `Relação cérebro-placentária: ${
            this.laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla
          }${
            this.laudoSelecionado.laudoJson.percentil2
              ? ' - Percentil ' +
                this.laudoSelecionado.laudoJson.percentil2 +
                ''
              : ''
          }${
            this.laudoSelecionado.laudoJson.indiceOpcaoCerePlac
              ? ' ' +
                this.laudoSelecionado.laudoJson.indiceOpcaoCerePlac +
                ' para idade gestacional'
              : ''
          }.\n`
        );
      if (this.laudoSelecionado.laudoJson.opcaoVelocidade)
        todos.push(
          `Velocidade de Pico Sistólica: ${
            this.laudoSelecionado.laudoJson.opcaoVelocidade
          }${
            this.laudoSelecionado.laudoJson.opcaoVelocidade2
              ? ' cm/s, correspondendo a ' +
                this.laudoSelecionado.laudoJson.opcaoVelocidade2 +
                ' múltiplos da mediana'
              : ''
          }.\n`
        );
        todos.push(`\n`);

      if (this.camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD) {
        todos.push(`\n<h4>Artéria uterina</h4>`);
      }

      if (
        this.camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD &&
        this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD
      ) {
        todos.push(
          `Índice de Pulsatilidade (direita): ${this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD}\n`
        );
      }
      if (
        this.camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE &&
        this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
      ) {
        todos.push(
          `Índice de Pulsatilidade (esquerda): ${this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE}\n`
        );
      }

      if (this.mediaIndice)
        todos.push(
          `Índice de Pulsatilidade médio: ${this.mediaIndice}${
            this.laudoSelecionado.laudoJson.percentilArtMed
              ? ' - Percentil ' +
                this.laudoSelecionado.laudoJson.percentilArtMed +
                ''
              : ''
          }${
            this.laudoSelecionado.laudoJson.indiceOpcaoArtMed
              ? ' ' +
                this.laudoSelecionado.laudoJson.indiceOpcaoArtMed +
                ' para idade gestacional'
              : ''
          }.\n`
        );

      // if (
      //   this.camposExibidos.exibeDuctoVenoso &&
      //   this.laudoSelecionado.laudoJson.ductoVenoso
      // ) {
      //   todos.push(`${this.laudoSelecionado.laudoJson.ductoVenoso}\n`);
      //   if (
      //     this.laudoSelecionado.laudoJson.ductoVenoso === 'Ducto Venoso' &&
      //     this.laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso
      //   ) {
      //     todos.push(
      //       ` índice de Pulsatilidade: ${this.laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso} ${this.laudoSelecionado.laudoJson.indiceOpcao4} para idade gestacional.`
      //     );
      //   }
      // }

      todos.push(`\n`);

      todos.push(`\n<h4>Conclusão</h4>\n`);

      if (this.laudoSelecionado.laudoJson.checkFluxoUmb) {
        todos.push(`Fluxo umbilical normal para a idade gestacional.\n`);
      }
      if (this.laudoSelecionado.laudoJson.checkAusFet) {
        todos.push(`Ausência de centralização fetal.\n`);
      }
      if (this.laudoSelecionado.laudoJson.checkRelCerNormal) {
        todos.push(
          `Relação cerebro-placentária normal para a idade gestacional.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkFluxoUmbRes) {
        todos.push(`Fluxo umbilical com resistência elevada.\n`);
      }
      if (this.laudoSelecionado.laudoJson.checkRelCerAcima) {
        todos.push(
          `Relação cerebro-placentária acima do percentil esperado para a idade gestacional. Convém monitorar.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkCentraFet) {
        todos.push(`Centralização fetal.\n`);
      }
      if (this.laudoSelecionado.laudoJson.checkDucto) {
        todos.push(
          `Ducto venoso ${this.laudoSelecionado.laudoJson.ductoVenNormAlt}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkIndiceAcima) {
        todos.push(
          `Indice de pulsatilidade médio acima do percentil esperado para a idade gestacional, aumentando o risco de pré-eclâmpsia e restrição de crescimento precoce.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkIndiceDentro) {
        todos.push(
          `Indice de pulsatilidade médio dentro do percentil normal para a idade gestacional, reduzindo o risco de pré-eclâmpsia e restrição de crescimento precoce.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkPicoAnemLeve) {
        todos.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia leve.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkPicoAnemMod) {
        todos.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia moderada.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkPicoGra) {
        todos.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia grave.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdicionais) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdicionais}\n`
        );
      }
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);
      todos.push(`\n`);


      if (this.camposExibidos.exibeColoUterino) {
        todos.push(`<h4>Perfil Cervical</h4>`);
      }
      todos.push(`\n`);

      if (
        this.camposExibidos.exibeColoUterino &&
        this.laudoSelecionado.laudoJson.coloUterino
      ) {
        todos.push(
          `Colo uterino ${this.laudoSelecionado.laudoJson.coloUterino}${
            this.laudoSelecionado.laudoJson.coloMedidaEmRepouco
              ? ', com menor comprimento de ' +
                this.laudoSelecionado.laudoJson.coloMedidaEmRepouco +
                ' mm (colo avaliado por três minutos)'
              : ''
          }.\n`
        );
      }
      // if (
      //   this.camposExibidos.exibeColoPassouAMedir &&
      //   this.laudoSelecionado.laudoJson.coloPassouAMedir
      // ) {
      //   todos.push(
      //     `Após três minutos de avaliação, o colo ${
      //       this.laudoSelecionado.laudoJson.coloPassouAMedir
      //     }${
      //       this.laudoSelecionado.laudoJson.cmdois
      //         ? ' ' + this.laudoSelecionado.laudoJson.cmdois
      //         : ''
      //     } cm.\n`
      //   );
      // }

      if (
        this.camposExibidos.exibeOrificioCervicalInterno &&
        this.laudoSelecionado.laudoJson.orificioCervicalInterno
      ) {
        todos.push(
          `Orifício cervical interno ${
            this.laudoSelecionado.laudoJson.orificioCervicalInterno
          }${
            this.laudoSelecionado.laudoJson.orificioCervicalInterno === 'aberto'
              ? ` com ${this.laudoSelecionado.laudoJson.orificioCervicalInterno2} mm`
              : ''
          }.\n`
        );
      }

      if (
        this.camposExibidos.exibeProtusaoBolsaAmniotica &&
        this.laudoSelecionado.laudoJson.protusaoBolsaAmniotica
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.\n`
        );
      }
      if (
        this.camposExibidos.exibeSinalDeSludge &&
        this.laudoSelecionado.laudoJson.sinalDeSludge
      ) {
        todos.push(
          `Sinal de sludge ${this.laudoSelecionado.laudoJson.sinalDeSludge}.\n`
        );
      }

      if (
        this.camposExibidos.exibePolipoEndocervicalMedindo &&
        this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo}${
            this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo ===
            'Observamos pólipo endocervical medindo'
              ? ` ${this.laudoSelecionado.laudoJson.polipoEndocervicalMedindoCm} mm`
              : ''
          }.\n`
        );
      }

      todos.push(`\n<h4>Conclusão</h4>`);

      if (
        this.camposExibidos.exibeColoUterino2 &&
        this.laudoSelecionado.laudoJson.coloUterino2
      ) {
        todos.push(
          `Colo uterino ${this.laudoSelecionado.laudoJson.coloUterino2}.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical}.\n`
        );
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 35);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },

    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),
    calcularIdadeEMeses() {
    const dataNascimento = moment(this.laudoSelecionado.laudoJson.dataNascimento);
    const hoje = moment();
    

    const anos = hoje.diff(dataNascimento, 'years');
    dataNascimento.add(anos, 'years');
    const meses = hoje.diff(dataNascimento, 'months');
    
      this.laudoSelecionado.laudoJson.anos = anos
      this.laudoSelecionado.laudoJson.meses = meses
      
      this.$forceUpdate()
    
  },
  calculaImc(){
      
      
      if(!this.laudoSelecionado.laudoJson.altura || !this.laudoSelecionado.laudoJson.peso) {
        return}
      let altura = this.laudoSelecionado.laudoJson.altura
      let peso = this.laudoSelecionado.laudoJson.peso
      altura = altura / 100; // converter para metros

    // Calculando o IMC
      const imc = peso / (altura * altura);
      this.laudoSelecionado.laudoJson.imc = imc.toFixed(2)
      
      this.$forceUpdate()

    },
    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;
            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },

    getIndicePulsatilidade() {
      //verificar se o valor tá entre 20 e 42

      let x;
      let semanas;

      if (
        !!this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical
      ) {
        x = this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical;
      } else {
        return;
      }
      if (
        !!this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
      ) {
        semanas = parseInt(
          this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
        );
      } else {
        return;
      }

      if (semanas >= 20 && semanas <= 42) {
        let valores = this.getValoresIndice(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);

        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudoSelecionado.laudoJson.percentil = resultado;
      } else {
        this.laudoSelecionado.laudoJson.percentil = '';
      }

      this.$forceUpdate();
    },
    getValoresIndice(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [1.04, 2.03];
      tabelaIndices[21] = [0.98, 1.96];
      tabelaIndices[22] = [0.92, 1.9];
      tabelaIndices[23] = [0.86, 1.85];
      tabelaIndices[24] = [0.81, 1.79];
      tabelaIndices[25] = [0.76, 1.74];
      tabelaIndices[26] = [0.71, 1.69];
      tabelaIndices[27] = [0.67, 1.65];
      tabelaIndices[28] = [0.63, 1.61];
      tabelaIndices[29] = [0.59, 1.57];
      tabelaIndices[30] = [0.56, 1.54];
      tabelaIndices[31] = [0.53, 1.51];
      tabelaIndices[32] = [0.5, 1.48];
      tabelaIndices[33] = [0.48, 1.46];
      tabelaIndices[34] = [0.46, 1.44];
      tabelaIndices[35] = [0.44, 1.43];
      tabelaIndices[36] = [0.43, 1.42];
      tabelaIndices[37] = [0.42, 1.41];
      tabelaIndices[38] = [0.42, 1.4];
      tabelaIndices[39] = [0.42, 1.4];
      tabelaIndices[40] = [0.42, 1.4];
      tabelaIndices[41] = [0.42, 1.41];
      tabelaIndices[42] = [0.43, 1.42];
      return tabelaIndices[valSemanas];
    },
    getACM() {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      let yRelacao;

      if (
        !!this.laudoSelecionado.laudoJson
          .indicePulsatilidadeArteriaCerebralMedia
      ) {
        x =
          this.laudoSelecionado.laudoJson
            .indicePulsatilidadeArteriaCerebralMedia;
      } else {
        return;
      }
      if (
        !!this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
      ) {
        semanas = parseInt(
          this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
        );
      } else {
        return;
      }
      if (
        !!this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical
      ) {
        yRelacao =
          this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical;

        let indiceRCP = x / yRelacao;
        this.laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla =
          indiceRCP.toFixed(2);
        let valores1 = this.getRelacaoCP(semanas);
        let menorvalor1 = valores1[0];
        let maiorvalor1 = valores1[1];
        let percentilRCP =
          5 +
          ((95 - 5) * (indiceRCP - menorvalor1)) / (maiorvalor1 - menorvalor1);

        percentilRCP = percentilRCP.toFixed(0);
        percentilRCP = percentilRCP > 100 ? ' >100 ' : percentilRCP;
        percentilRCP = percentilRCP < 0 ? ' <1 ' : percentilRCP;

        this.laudoSelecionado.laudoJson.percentil2 = percentilRCP;
      }

      if (semanas >= 20 && semanas <= 42) {
        let valores = this.getValoresACM(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);

        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudoSelecionado.laudoJson.percentil3 = resultado;
      } else {
        this.laudoSelecionado.laudoJson.percentil3 = '';
      }

      this.$forceUpdate();
    },
    getRelacaoCP(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [4.17, 0.9];
      tabelaIndices[21] = [4.35, 0.91];
      tabelaIndices[22] = [4.55, 0.92];
      tabelaIndices[23] = [4.76, 0.93];
      tabelaIndices[24] = [5.0, 0.93];
      tabelaIndices[25] = [5.0, 0.93];
      tabelaIndices[26] = [5.26, 0.94];
      tabelaIndices[27] = [5.56, 0.95];
      tabelaIndices[28] = [5.88, 0.96];
      tabelaIndices[29] = [6.25, 0.97];
      tabelaIndices[30] = [6.67, 0.98];
      tabelaIndices[31] = [6.67, 0.98];
      tabelaIndices[32] = [7.14, 0.99];
      tabelaIndices[33] = [7.69, 1.0];
      tabelaIndices[34] = [8.33, 1.01];
      tabelaIndices[35] = [9.09, 1.02];
      tabelaIndices[36] = [10.0, 1.03];
      tabelaIndices[37] = [10.0, 1.04];
      tabelaIndices[38] = [11.11, 1.04];
      tabelaIndices[39] = [12.5, 1.05];
      tabelaIndices[40] = [14.29, 1.06];
      tabelaIndices[41] = [16.67, 1.08];
      tabelaIndices[42] = [20.0, 1.09];
      return tabelaIndices[valSemanas];
    },
    getValoresACM(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [1.36, 2.31];
      tabelaIndices[21] = [1.4, 2.34];
      tabelaIndices[22] = [1.44, 2.37];
      tabelaIndices[23] = [1.47, 2.4];
      tabelaIndices[24] = [1.49, 2.42];
      tabelaIndices[25] = [1.51, 2.44];
      tabelaIndices[26] = [1.52, 2.45];
      tabelaIndices[27] = [1.53, 2.45];
      tabelaIndices[28] = [1.53, 2.46];
      tabelaIndices[29] = [1.53, 2.45];
      tabelaIndices[30] = [1.52, 2.44];
      tabelaIndices[31] = [1.51, 2.43];
      tabelaIndices[32] = [1.49, 2.41];
      tabelaIndices[33] = [1.46, 2.39];
      tabelaIndices[34] = [1.43, 2.36];
      tabelaIndices[35] = [1.4, 2.32];
      tabelaIndices[36] = [1.36, 2.28];
      tabelaIndices[37] = [1.32, 2.24];
      tabelaIndices[38] = [1.27, 2.19];
      tabelaIndices[39] = [1.21, 2.14];
      tabelaIndices[40] = [1.15, 2.08];
      tabelaIndices[41] = [1.08, 2.01];
      tabelaIndices[42] = [1.01, 1.94];
      return tabelaIndices[valSemanas];
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        try {
          this.carregamentoDoLaudo = false;
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo =
        'ULTRASSONOGRAFIA MORFOLÓGICA COM DOPPLER E PERFIL CERVICAL';
    },
    desativaLoading() {
      this.loadingImprimir = false;
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },
    formataLaudo() {
      if (this.exibeObs) {
        this.laudoSelecionado.laudoJson.exibeObs = false;
      } else {
        this.laudoSelecionado.laudoJson.exibeObs = true;
      }
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.morfologicaComDopplerCervical',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },

    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },

  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },

  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      // this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
